import React, { FC } from 'react';

import { FilterContainer, SkeletonCard, SkeletonText } from '@accesstel/pcm-ui';

export const NotificationPaneSkeleton: FC = () => {
    return (
        <div className='absolute top-full right-0 mt-2 z-50'>
            <FilterContainer title='Notifications' width='w-96' primaryContent={<LoadingSection />} />
        </div>
    );
};

const LoadingSection = () => {
    return (
        <div className='px-3 pb-2'>
            <div className='flex flex-col gap-2'>
                <SkeletonText size='normal' characters={7} />
                <SkeletonCard className='rounded-lg h-24' />
                <SkeletonCard className='rounded-lg h-24' />
                <SkeletonCard className='rounded-lg h-24' />
            </div>
        </div>
    );
};
