import React, { FC } from 'react';

import { Button, FormikDurationSelect, FormikGeneralSelect } from '@accesstel/pcm-ui';

import { Form, useFormikContext } from 'formik';

import { DeliverySettingsFormValues } from '../schema';
import { NotificationDeliveryType as NotificationDeliveryTypeRaw } from './__generated__/DeliverySettingsFragment.graphql';

type NotificationDeliveryType = Exclude<NotificationDeliveryTypeRaw, '%future added value'>;

const DeliveryMethods: Record<Exclude<NotificationDeliveryType, '%future added value'>, string> = {
    None: 'None',
    Email: 'Email',
    InApp: 'In-App',
};

export const DeliverySettingsContent: FC = () => {
    const { dirty, isSubmitting, isValid, setFieldValue, values } = useFormikContext<DeliverySettingsFormValues>();

    const isDelayRelevant = React.useMemo(() => {
        const deliveryMethodValues = [
            values.batteryDischarge,
            values.generatorRun,
            values.productUpdate,
            values.scheduledBatteryTestComplete,
        ];

        return deliveryMethodValues.some(value => value === 'InApp');
    }, [values.batteryDischarge, values.generatorRun, values.productUpdate, values.scheduledBatteryTestComplete]);

    return (
        <Form>
            <div className='space-y-4 grid grid-cols-2 pt-6 gap-x-6 pr-6'>
                <div className='col-span-2 font-bold'>Delivery Method</div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='productUpdate'
                        placeHolder='Product Updates'
                        light
                        variant='outlined'
                        items={Object.keys(DeliveryMethods)}
                        renderItem={item => DeliveryMethods[item as NotificationDeliveryType]}
                        onSelectItem={item => setFieldValue('productUpdate', item)}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='batteryDischarge'
                        placeHolder='Battery Discharges'
                        light
                        variant='outlined'
                        items={Object.keys(DeliveryMethods)}
                        renderItem={item => DeliveryMethods[item as NotificationDeliveryType]}
                        onSelectItem={item => setFieldValue('batteryDischarge', item)}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='scheduledBatteryTestComplete'
                        placeHolder='Completed Battery Tests'
                        light
                        variant='outlined'
                        items={Object.keys(DeliveryMethods)}
                        renderItem={item => DeliveryMethods[item as NotificationDeliveryType]}
                        onSelectItem={item => setFieldValue('scheduledBatteryTestComplete', item)}
                    />
                </div>
                <div className='col-start-1'>
                    <FormikGeneralSelect
                        name='generatorRun'
                        placeHolder='Generator Runs'
                        light
                        variant='outlined'
                        items={Object.keys(DeliveryMethods)}
                        renderItem={item => DeliveryMethods[item as NotificationDeliveryType]}
                        onSelectItem={item => setFieldValue('generatorRun', item)}
                    />
                </div>
                <div className='col-span-2 font-bold'>Email Delivery Settings</div>
                <div className='col-start-1'>
                    <FormikDurationSelect
                        name='emailDelay'
                        placeHolder='Delay Duration'
                        light
                        variant='outlined'
                        disabled={!isDelayRelevant}
                        disabledText='Not applicable'
                    />
                </div>
            </div>
            <div className='flex flex-row gap-4 justify-end'>
                <Button
                    type='reset'
                    variant='white'
                    buttonText='Reset'
                    disabled={isSubmitting || !dirty}
                    size='small'
                />
                <Button
                    type='submit'
                    variant='primary'
                    buttonText='Save'
                    disabled={isSubmitting || !isValid || !dirty}
                    processing={isSubmitting}
                    size='small'
                />
            </div>
        </Form>
    );
};
