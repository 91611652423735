import React, { FC, Suspense, useEffect, useMemo, useState } from 'react';
import { useSubscription } from 'react-relay';

import { NotificationsIcon, Tooltip } from '@accesstel/pcm-ui';

import { graphql } from 'babel-plugin-relay/macro';
import { updateFavicon } from 'lib/favicon';
import { useQuery } from 'lib/query-helpers';

import { NotificationPane } from './NotificationPane';
import { NotificationPaneSkeleton } from './NotificationPaneSkeleton';
import {
    NotificationNewSubscription,
    NotificationNewSubscription$data,
} from './__generated__/NotificationNewSubscription.graphql';
import { NotificationUnreadTotalQuery } from './__generated__/NotificationUnreadTotalQuery.graphql';

export const Notification: FC = () => {
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [shouldPing, setShouldPing] = useState(false);

    const { data, retry: refetchUnreadCount } = useQuery<NotificationUnreadTotalQuery>(NotificationUnreadQuery, {});
    const unreadCount = data?.unreadNotifications.total;
    const hasUnreadNotifications = useMemo(() => unreadCount !== undefined && unreadCount > 0, [unreadCount]);

    useEffect(() => {
        updateFavicon(hasUnreadNotifications);
    }, [hasUnreadNotifications]);

    useEffect(() => {
        if (shouldPing) {
            const timer = setTimeout(() => {
                setShouldPing(false);
            }, 8_000);

            return () => clearTimeout(timer);
        }
    }, [isPaneOpen, shouldPing]);

    const subscriptionConfig = useMemo(
        () => ({
            subscription: NotificationSubscription,
            variables: {},
            onNext: (response: NotificationNewSubscription$data | null | undefined) => {
                if (!response?.onNotification) {
                    return;
                }

                refetchUnreadCount();

                if (!isPaneOpen) {
                    setShouldPing(true);
                }
            },
        }),
        [isPaneOpen, refetchUnreadCount]
    );

    useSubscription<NotificationNewSubscription>(subscriptionConfig);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            setIsPaneOpen(true);
            setShouldPing(false);
        } else if (event.key === 'Escape') {
            setIsPaneOpen(false);
        }
    };

    return (
        <div className='relative'>
            <Tooltip content='Notifications'>
                <div
                    role='button'
                    tabIndex={0}
                    aria-pressed={isPaneOpen}
                    aria-label='Notifications'
                    aria-labelledby='notifications'
                    aria-expanded={isPaneOpen}
                    aria-controls='notification-pane'
                    onClick={() => {
                        setIsPaneOpen(true);
                        setShouldPing(false);
                    }}
                    onKeyDown={handleKeyDown}
                >
                    <div
                        className={`${
                            isPaneOpen ? 'text-coralRegular' : 'text-eggplantRegular'
                        } hover:text-coralRegular focus:text-coralRegular outline-none w-4 h-4 cursor-pointer`}
                    >
                        <NotificationsIcon />
                    </div>
                    {unreadCount !== undefined && unreadCount > 0 && (
                        <div>
                            <div
                                className={`absolute -top-2 ${
                                    unreadCount > 9 ? '-right-4 rounded-xl' : '-right-2 rounded-full'
                                } bg-coralRegular w-4 h-4 ${shouldPing && !isPaneOpen ? 'animate-ping' : 'hidden'}`}
                            />
                            <div
                                className={`absolute -top-2 bg-coralRegular text-white px-1 flex items-center justify-center text-xs ${
                                    unreadCount > 9 ? '-right-4 rounded-xl' : '-right-2 rounded-full'
                                }`}
                            >
                                <span>{unreadCount > 9 ? '9+' : unreadCount}</span>
                            </div>
                        </div>
                    )}
                </div>
            </Tooltip>
            {isPaneOpen && !data && <NotificationPaneSkeleton />}
            {isPaneOpen && unreadCount !== undefined && data && (
                <Suspense fallback={<NotificationPaneSkeleton />}>
                    <div id='notification-pane' className='absolute top-full right-0 mt-2 z-50'>
                        <NotificationPane
                            setIsOpen={setIsPaneOpen}
                            unreadCount={unreadCount}
                            recount={() => refetchUnreadCount()}
                            notificationRef={data.notifications}
                        />
                    </div>
                </Suspense>
            )}
        </div>
    );
};

const NotificationUnreadQuery = graphql`
    query NotificationUnreadTotalQuery {
        unreadNotifications: notifications(filters: { read: { value: false } }) {
            total
        }
        notifications {
            ...NotificationPane_data
        }
    }
`;

const NotificationSubscription = graphql`
    subscription NotificationNewSubscription {
        onNotification {
            id
            type
        }
    }
`;
