/**
 * @generated SignedSource<<dc6df556900aa15c64c8331a39c6f4f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationDeliveryType = "Email" | "InApp" | "None" | "%future added value";
export type UnitApparentPower = "KiloVoltAmp" | "VoltAmp" | "%future added value";
export type UnitCharge = "AmpHour" | "MilliAmpHour" | "%future added value";
export type UnitCurrent = "Amp" | "Milliamp" | "%future added value";
export type UnitEnergy = "KiloWattHour" | "WattHour" | "%future added value";
export type UnitFlowRate = "LitrePerHour" | "USGallonPerHour" | "%future added value";
export type UnitPower = "KiloWatt" | "Watt" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitReactivePower = "KiloVoltAmpReactive" | "VoltAmpReactive" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitVoltage = "Millivolt" | "Volt" | "%future added value";
export type UnitVolume = "Litre" | "USGallon" | "%future added value";
export type UpdateUserPreferenceErrorType = "InvalidColumnId" | "InvalidNotificationsEmailDelay" | "InvalidTableId" | "InvalidTimezone" | "%future added value";
export type UserPreferenceIn = {
  notificationDelivery?: UserNotificationDeliveryPreferencesIn | null;
  notificationEmailDelay?: string | null;
  timezone?: string | null;
  units?: UserUnitPreferencesIn | null;
};
export type UserUnitPreferencesIn = {
  apparentPower?: UnitApparentPower | null;
  charge?: UnitCharge | null;
  current?: UnitCurrent | null;
  energy?: UnitEnergy | null;
  power?: UnitPower | null;
  pressure?: UnitPressure | null;
  reactivePower?: UnitReactivePower | null;
  temperature?: UnitTemperature | null;
  voltage?: UnitVoltage | null;
  volume?: UnitVolume | null;
  volumetricFlowRate?: UnitFlowRate | null;
};
export type UserNotificationDeliveryPreferencesIn = {
  batteryDischarge?: NotificationDeliveryType | null;
  generatorRun?: NotificationDeliveryType | null;
  productUpdate?: NotificationDeliveryType | null;
  scheduledBatteryTestComplete?: NotificationDeliveryType | null;
};
export type DeliverySettingsUpdateMutation$variables = {
  input: UserPreferenceIn;
};
export type DeliverySettingsUpdateMutation$data = {
  readonly updateCurrentUserPreferences: {
    readonly problems: ReadonlyArray<UpdateUserPreferenceErrorType> | null;
    readonly success: boolean;
  };
};
export type DeliverySettingsUpdateMutation = {
  response: DeliverySettingsUpdateMutation$data;
  variables: DeliverySettingsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "preferences",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPreferenceResult",
    "kind": "LinkedField",
    "name": "updateCurrentUserPreferences",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliverySettingsUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliverySettingsUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a15f9385321f97002a3272b30fd889a",
    "id": null,
    "metadata": {},
    "name": "DeliverySettingsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation DeliverySettingsUpdateMutation(\n  $input: UserPreferenceIn!\n) {\n  updateCurrentUserPreferences(preferences: $input) {\n    success\n    problems\n  }\n}\n"
  }
};
})();

(node as any).hash = "db069e9127413c96eb6b4c3b56507682";

export default node;
