import graphql from 'babel-plugin-relay/macro';
import {
    SiteExtraFilters,
    SiteFilterState,
    SiteTableColumn,
    SiteTableColumnId,
    siteToFilterObject,
} from 'filters/site';
import { SortDirection, TableState } from 'layouts';
import { DefaultTimeRange, TimeRange } from 'views/reports/ac-power/settings';

import { useTableQuery } from '../table-query';
import { SiteOrdering, SiteSortField, requestSitesQuery } from './__generated__/requestSitesQuery.graphql';

export function useSiteTableQuery(tableState: TableState<SiteTableColumnId>, filters: SiteFilterState) {
    return useTableQuery<SiteTableColumnId, requestSitesQuery>(
        RequestQuery,
        options => {
            const sortObject: SiteOrdering = {
                field: options.orderBy as SiteSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            const acReliabilityTimeRange: TimeRange =
                (filters.extraFilters[SiteExtraFilters.ACReliabilityTimeRange] as TimeRange | undefined) ??
                DefaultTimeRange;

            const filterObject = siteToFilterObject(filters);

            return {
                page: options.page,
                pageSize: options.pageSize,
                pageCount: options.pageCount,
                search: options.search,
                orderBy: sortObject,
                filter: filterObject,
                withSiteType: options.visibleColumns.includes(SiteTableColumn.Type),
                withPostcode: options.visibleColumns.includes(SiteTableColumn.Postcode),
                withBatteryStatus: options.visibleColumns.includes(SiteTableColumn.BatteryStatus),
                withBatteryCapacity: options.visibleColumns.includes(SiteTableColumn.BatteryCapacity),
                withBatteryTimeRemaining: options.visibleColumns.includes(SiteTableColumn.BatteryTotalTimeRemaining),
                withIncidentCount: options.visibleColumns.includes(SiteTableColumn.IncidentCount),
                withRiskRank: options.visibleColumns.includes(SiteTableColumn.ACRisk),
                withReliability: options.visibleColumns.includes(SiteTableColumn.ACReliability),
                withDurationSum: options.visibleColumns.includes(SiteTableColumn.OutageDurationSum),
                acTimeRange: acReliabilityTimeRange,
            };
        },
        tableState
    );
}

const RequestQuery = graphql`
    query requestSitesQuery(
        $acTimeRange: ACTimeRange!
        $page: Int = 1
        $pageSize: Int = 50
        $pageCount: Int
        $search: String = ""
        $orderBy: SiteOrdering
        $filter: SiteFilter
        $withSiteType: Boolean = false
        $withPostcode: Boolean = false
        $withBatteryStatus: Boolean = false
        $withBatteryCapacity: Boolean = false
        $withBatteryTimeRemaining: Boolean = false
        $withIncidentCount: Boolean = false
        $withRiskRank: Boolean = false
        $withReliability: Boolean = false
        $withDurationSum: Boolean = false
    ) {
        sites(
            page: $page
            pageSize: $pageSize
            pageCount: $pageCount
            search: $search
            onlyProvisioningStatuses: Active
            orderBy: $orderBy
            filters: $filter
            acReliabilityTimeRange: $acTimeRange
        ) {
            total
            data {
                __typename
                id
                name
                type @include(if: $withSiteType)
                address {
                    state
                    postcode @include(if: $withPostcode)
                }
                devices {
                    total
                    data {
                        __typename
                        id
                        name
                        health
                        battery {
                            installed
                            metrics {
                                latestStatus
                            }
                        }
                        type {
                            category
                        }
                        site {
                            id
                        }
                    }
                }
                health {
                    status
                    commonStatus
                    multipleStatuses
                }
                batteryMetrics {
                    commonStatus @include(if: $withBatteryStatus)
                    multipleStatuses @include(if: $withBatteryStatus)
                    totalCapacity(unit: AmpHour) @include(if: $withBatteryCapacity)
                    totalTimeRemaining(unit: Hour) @include(if: $withBatteryTimeRemaining)
                }
                acPower {
                    reliability(timeRange: $acTimeRange) {
                        mtbf {
                            current
                        }
                        mttr {
                            current
                        }
                        percentile @include(if: $withReliability) {
                            current
                        }
                        incidentCount @include(if: $withIncidentCount)
                        durationSum(worstStatus: [Outage]) @include(if: $withDurationSum)
                        riskRank @include(if: $withRiskRank)
                    }
                }
                attributes {
                    name
                    value
                }
            }
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
        }
        overallSites: sites {
            total
        }
        globalDeviceHealth {
            status
            count
        }
    }
`;
