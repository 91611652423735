import { useCallback } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay';

import { useToast } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { RecordSourceSelectorProxy } from 'relay-runtime';

import { watchControlStartWatchingMutation } from './__generated__/watchControlStartWatchingMutation.graphql';
import { watchControlStopWatchingMutation } from './__generated__/watchControlStopWatchingMutation.graphql';
import { watchControlWatchStatusQuery } from './__generated__/watchControlWatchStatusQuery.graphql';
import { NotificationType, WatchEntity, WatchEntityNames } from './common';
import { NotificationTypesPerEntity } from './entity-options';

interface NotificationWatchControls {
    /**
     * Updates the current users watch preferences
     * @param types The desired notification types
     */
    updateWatchPreferences(types: NotificationType[]): void;
    /**
     * Starts watching for all notifications
     */
    startWatching(): void;
    /**
     * Stops watching the entity
     */
    stopWatching(): void;

    /**
     * True if the user is already watching the entity
     */
    isWatchingEntity: boolean;
    /**
     * A list of all watched notifications
     */
    watchedNotifications: NotificationType[];
    /**
     * True if the user is unable to add more watches
     */
    isWatchLimitReached: boolean;
}

/**
 * Allows for controlling the notification watches of the current user.
 * @param entityId The ID of the entity to be watched. Must match the entityType
 * @param entityType The type of entity the ID is fore
 * @returns
 */
export function useWatchNotifications(entityId: string, entityType: WatchEntity): NotificationWatchControls {
    const { show } = useToast();

    const { currentUser } = useLazyLoadQuery<watchControlWatchStatusQuery>(
        graphql`
            query watchControlWatchStatusQuery($id: ID!) {
                currentUser {
                    notificationWatch(entity: $id) {
                        enabledNotifications
                    }

                    notificationWatches {
                        total
                    }

                    notificationWatchLimit
                }
            }
        `,
        {
            id: entityId,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );

    const [callStartWatching] = useMutation<watchControlStartWatchingMutation>(WatchEntityMutation);
    const [callStopWatching] = useMutation<watchControlStopWatchingMutation>(UnwatchEntityMutation);

    const isAtLimit = currentUser.notificationWatches.total >= currentUser.notificationWatchLimit;

    const isWatching = currentUser.notificationWatch != null;
    let selectedTypes: NotificationType[];
    if (isWatching) {
        selectedTypes = currentUser.notificationWatch.enabledNotifications as NotificationType[];

        selectedTypes = selectedTypes.filter(type => NotificationTypesPerEntity[entityType].includes(type));
    } else {
        // Everything by default
        selectedTypes = NotificationTypesPerEntity[entityType];
    }

    const updateWatchPreferences = useCallback(
        (notificationTypes?: NotificationType[]) => {
            if (notificationTypes && notificationTypes.length === 0) {
                // If nothing is selected, they probably want to stop watching
                callStopWatching({
                    variables: {
                        id: entityId,
                    },
                    optimisticUpdater(store) {
                        removeWatchState(entityId, entityType, store);
                    },
                    updater(store, data) {
                        if (!data.unwatchForNotifications?.success) {
                            return;
                        }
                        removeWatchState(entityId, entityType, store);
                    },
                });
                return;
            }

            // Handle no-op. Don't send an update
            if (
                notificationTypes &&
                selectedTypes.length === notificationTypes.length &&
                selectedTypes.every(type => notificationTypes.includes(type))
            ) {
                return;
            }

            callStartWatching({
                variables: {
                    id: entityId,
                    notificationTypes,
                },
                onCompleted(response) {
                    if (response.watchForNotifications.success) {
                        return;
                    }

                    const problems = response.watchForNotifications.problems ?? [];

                    if (problems.some(problem => problem === 'TooManyWatches')) {
                        show({
                            text: 'You have reached the watch limit. Unwatch something first.',
                            variant: 'error',
                            displayTime: 8000,
                        });
                    } else if (problems.some(problem => problem === 'InvalidNotificationType')) {
                        // This is an US problem, not the user. We should not be providing this option
                        captureMessage('Attempted to send invalid notification type for entity type', scope =>
                            scope
                                .setExtra('Entity Type', entityType)
                                .setExtra('Entity Id', entityId)
                                .setExtra('Notification Types', notificationTypes)
                        );
                        show({
                            text: `Unable to watch this ${WatchEntityNames[entityType]}, please try again later`,
                            variant: 'error',
                        });
                    }
                },
                optimisticUpdater(store) {
                    updateWatchState(entityId, entityType, store, notificationTypes);
                },
                updater(store, data) {
                    if (!data.watchForNotifications.success) {
                        return;
                    }

                    updateWatchState(
                        entityId,
                        entityType,
                        store,
                        data.watchForNotifications.watch?.enabledNotifications
                    );
                },
            });
        },
        [callStartWatching, callStopWatching, entityId, entityType, selectedTypes, show]
    );

    const stopWatching = useCallback(() => {
        if (!isWatching) {
            return;
        }

        callStopWatching({
            variables: {
                id: entityId,
            },
            optimisticUpdater(store) {
                removeWatchState(entityId, entityType, store);
            },
            updater(store, data) {
                if (!data.unwatchForNotifications?.success) {
                    return;
                }
                removeWatchState(entityId, entityType, store);
            },
        });
    }, [callStopWatching, entityId, entityType, isWatching]);

    const startWatching = useCallback(() => {
        updateWatchPreferences();

        function undo() {
            callStopWatching({
                variables: {
                    id: entityId,
                },
                optimisticUpdater(store) {
                    removeWatchState(entityId, entityType, store);
                },
                updater(store, data) {
                    if (!data.unwatchForNotifications?.success) {
                        return;
                    }
                    removeWatchState(entityId, entityType, store);
                },
            });
        }

        show({
            text: `Now watching this ${WatchEntityNames[entityType]} for notifications`,
            variant: 'info',
            displayTime: 5000,
            actions: [
                {
                    title: 'Undo',
                    onClick: undo,
                },
            ],
        });
    }, [callStopWatching, entityId, entityType, show, updateWatchPreferences]);

    return {
        isWatchingEntity: isWatching,
        isWatchLimitReached: isAtLimit,
        watchedNotifications: selectedTypes,
        startWatching,
        updateWatchPreferences,
        stopWatching,
    };
}

function updateWatchState(
    entityId: string,
    entityType: WatchEntity,
    store: RecordSourceSelectorProxy,
    notificationTypes?: readonly NotificationType[]
) {
    const root = store.getRoot();
    const currentUser = root.getLinkedRecord('currentUser');

    if (!currentUser) {
        return;
    }

    const watch = currentUser.getOrCreateLinkedRecord('notificationWatch', 'NotificationWatch', {
        entity: entityId,
    });

    if (notificationTypes != null) {
        watch.setValue(notificationTypes as NotificationType[], 'enabledNotifications');
    } else {
        watch.setValue(NotificationTypesPerEntity[entityType], 'enabledNotifications');
    }
}

function removeWatchState(entityId: string, entityType: WatchEntity, store: RecordSourceSelectorProxy) {
    const root = store.getRoot();
    const currentUser = root.getLinkedRecord('currentUser');

    if (!currentUser) {
        return;
    }

    const watch = currentUser.getLinkedRecord('notificationWatch', {
        entity: entityId,
    });

    if (watch) {
        store.delete(watch.getDataID());
    }
}

const WatchEntityMutation = graphql`
    mutation watchControlStartWatchingMutation($id: ID!, $notificationTypes: [NotificationType!]) {
        watchForNotifications(entity: $id, notifications: $notificationTypes) {
            success
            problems
            watch {
                enabledNotifications
            }
        }
    }
`;

const UnwatchEntityMutation = graphql`
    mutation watchControlStopWatchingMutation($id: ID!) {
        unwatchForNotifications(entity: $id) {
            success
            problems
        }
    }
`;
