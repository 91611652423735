/**
 * @generated SignedSource<<8ea7f8cffcd38e998ebc7cac59167cd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NotificationDeliveryType = "Email" | "InApp" | "None" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliverySettingsFragment$data = {
  readonly notifications: {
    readonly delivery: {
      readonly batteryDischarge: NotificationDeliveryType;
      readonly generatorRun: NotificationDeliveryType;
      readonly productUpdate: NotificationDeliveryType;
      readonly scheduledBatteryTestComplete: NotificationDeliveryType;
    };
    readonly emailDelay: string;
  };
  readonly " $fragmentType": "DeliverySettingsFragment";
};
export type DeliverySettingsFragment$key = {
  readonly " $data"?: DeliverySettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliverySettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliverySettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationsSettings",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailDelay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNotificationDeliveryPreferences",
          "kind": "LinkedField",
          "name": "delivery",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "generatorRun",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "batteryDischarge",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scheduledBatteryTestComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productUpdate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserPreferences",
  "abstractKey": null
};

(node as any).hash = "4df65fc2618a659ea27d297b00ab96c9";

export default node;
