import React, { FC, useEffect, useState } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { Button, NavigateTarget } from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { getCurrentUserUnitsPref, useCurrentUserUnitsPref } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { Paths } from 'lib/routes';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder, WrappedDevicePlaceholderProps } from '../../components/WrappedDevicePlaceholder';
import { LiveDataRefreshInterval } from '../../settings';
import { DeviceOverview } from './DeviceOverview';
import { GatewayOverview } from './GatewayOverview';
import { GeneratorOverview } from './GeneratorOverview';
import { OverviewContentQuery, OverviewContentQuery$variables } from './__generated__/OverviewContentQuery.graphql';

type Device = NonNullable<
    NonNullable<NonNullable<OverviewContentQuery['response']['device']>['dualPlaneCompanion']>['device']
>;

export const getCompanionDevicePlaneParameters = (deviceId: string): WrappedDevicePlaceholderProps => {
    const url: NavigateTarget = {
        pathname: generatePath(Paths.AddDevice, { deviceId }),
        search: {
            companion: deviceId,
        },
    };

    return {
        message: 'No dual plane companion device',
        buttonText: 'Add Device',
        disabledButtonMessage: 'You do not have permission to add a device.',
        navigateTo: url,
    };
};

export interface OverviewContentProps {
    queryRef: PreloadedQuery<OverviewContentQuery>;
    deviceId: string;
}

export const OverviewContent: FC<OverviewContentProps> = ({ queryRef, deviceId }) => {
    const initialData = usePreloadedQuery(OverviewQuery, queryRef);
    const environment = useRelayEnvironment();
    const unitPreferences = useCurrentUserUnitsPref();
    const [data, setData] = useState(initialData);

    // Live refresh the data
    useEffect(() => {
        const refetchInterval = setInterval(() => {
            const refetchVariables: OverviewContentQuery$variables = {
                id: deviceId,
                unitVolume: unitPreferences.volume,
            };

            fetchQuery<OverviewContentQuery>(environment, OverviewQuery, refetchVariables, {
                fetchPolicy: 'network-only',
            }).subscribe({
                next(value) {
                    setData(value);
                },
                error(error: unknown) {
                    captureException(error, scope => {
                        scope.setExtra('variables', refetchVariables);
                        scope.setTag('Component', 'InsightsContent');
                        return scope;
                    });
                },
            });
        }, LiveDataRefreshInterval);

        return () => clearInterval(refetchInterval);
    }, [deviceId, environment, unitPreferences.volume]);

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in OverviewContent', scope => {
            scope.setExtra('data', initialData);
            scope.setTag('Component', 'OverviewContent');
            return scope;
        });
        return null;
    }

    const category = data.device.type.category;

    let primaryDevice: Device = data.device;
    let secondaryDevice: Device | undefined | null = data.device.dualPlaneCompanion?.device;

    // Order alphabetically
    if (secondaryDevice && secondaryDevice.name < primaryDevice.name) {
        [primaryDevice, secondaryDevice] = [secondaryDevice, primaryDevice];
    }

    const gridContainerClasses = 'grid grid-cols-2 gap-4';

    if (category === 'Generator') {
        return (
            <div className={gridContainerClasses}>
                <DevicePane
                    deviceId={data.device.id}
                    title={primaryDevice.name}
                    subtitle={primaryDevice.type.displayName}
                    health={primaryDevice.health}
                    lastUpdate={primaryDevice.lastUpdate}
                    lastOnline={primaryDevice.lastOnline}
                >
                    <GeneratorOverview generator={data.device} generatorId={data.device.id} settings={data.settings} />
                </DevicePane>
            </div>
        );
    }

    if (category === 'Gateway') {
        if (data.gatewayConnectedDevices.length === 1) {
            return (
                <div className={gridContainerClasses}>
                    <DevicePane
                        deviceId={data.device.id}
                        title={primaryDevice.name}
                        subtitle={primaryDevice.type.displayName}
                        health={primaryDevice.health}
                        lastUpdate={primaryDevice.lastUpdate}
                        lastOnline={primaryDevice.lastOnline}
                    >
                        <GatewayOverview gateway={data.device} connectedDevices={data.gatewayConnectedDevices[0]} />
                    </DevicePane>
                </div>
            );
        } else {
            return (
                <div className={gridContainerClasses}>
                    <DevicePane
                        deviceId={data.device.id}
                        title={primaryDevice.name}
                        subtitle={primaryDevice.type.displayName}
                        health={primaryDevice.health}
                        lastUpdate={primaryDevice.lastUpdate}
                        lastOnline={primaryDevice.lastOnline}
                    >
                        <div className='flex flex-col items-center'>
                            <div className='text-4xl font-semibold'>Error</div>
                            <div className='text-xl pb-4'>Something went wrong</div>
                            <Button
                                buttonText={'Go to site overview'}
                                to={generatePath(Paths.SiteViewViewSiteOverview, { siteId: data.device.site.id })}
                            />
                        </div>
                    </DevicePane>
                </div>
            );
        }
    }

    return (
        <div className={gridContainerClasses}>
            {primaryDevice && (
                <DevicePane
                    deviceId={data.device.id}
                    title={primaryDevice.name}
                    subtitle={primaryDevice.type.displayName}
                    health={primaryDevice.health}
                    lastUpdate={primaryDevice.lastUpdate}
                    lastOnline={primaryDevice.lastOnline}
                >
                    <DeviceOverview device={primaryDevice} deviceId={primaryDevice.id} />
                </DevicePane>
            )}
            {(secondaryDevice && (
                <DevicePane
                    deviceId={data.device.id}
                    title={secondaryDevice.name}
                    subtitle={secondaryDevice.type.displayName}
                    health={secondaryDevice.health}
                    lastUpdate={secondaryDevice.lastUpdate}
                    lastOnline={secondaryDevice.lastOnline}
                >
                    <DeviceOverview device={secondaryDevice} deviceId={secondaryDevice.id} />
                </DevicePane>
            )) || <WrappedDevicePlaceholder {...getCompanionDevicePlaneParameters(primaryDevice.id)} />}
        </div>
    );
};

export const OverviewQuery = graphql`
    query OverviewContentQuery($id: ID!, $unitVolume: UnitVolume) {
        settings {
            ...GeneratorOverview_settings
        }
        gatewayConnectedDevices(gatewayIds: [$id]) {
            ...GatewayOverview_connectedDevices
        }
        device(id: $id) {
            id
            name
            health
            lastUpdate
            lastOnline
            type {
                displayName
                category
            }
            site {
                id
            }
            ...DeviceOverview_device
            ...GeneratorOverview_device @arguments(unitVolume: $unitVolume)
            ...GatewayOverview_device

            dualPlaneCompanion {
                device {
                    id
                    name
                    health
                    lastUpdate
                    lastOnline
                    type {
                        displayName
                    }
                    ...DeviceOverview_device
                }
            }
        }
    }
`;

export async function loadOverviewPageData(id: string) {
    const unitPreferences = await getCurrentUserUnitsPref();

    return loadQuery(
        getGlobalEnvironment(),
        OverviewQuery,
        {
            id,
            unitVolume: unitPreferences.volume,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
