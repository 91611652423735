import graphql from 'babel-plugin-relay/macro';

export const averageTemperatureSparklineQuery = graphql`
    query queries_AverageTemperatureSparklineQuery(
        $begin: Timestamp!
        $end: Timestamp!
        $interval: Duration!
        $unitTemperature: UnitTemperature
    ) {
        batteryMetrics {
            temperature(unit: $unitTemperature, begin: $begin, end: $end, interval: $interval) {
                values {
                    timestamp
                    value
                }
                trend {
                    percent
                }
            }
        }
    }
`;

export const averageEnergySparklineQuery = graphql`
    query queries_AverageEnergySparklineQuery($begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        batteryMetrics {
            energy(unit: KiloWattHour, begin: $begin, end: $end, interval: $interval) {
                values {
                    timestamp
                    value
                }
                trend {
                    percent
                }
            }
        }
    }
`;

export const averageCurrentQuery = graphql`
    query queries_AverageCurrentQuery($begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        batteryMetrics {
            current(unit: Amp, begin: $begin, end: $end, interval: $interval) {
                values {
                    timestamp
                    value
                }
            }
        }
    }
`;

export const averageEnergyQuery = graphql`
    query queries_AverageEnergyQuery($begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        batteryMetrics {
            energy(unit: KiloWattHour, begin: $begin, end: $end, interval: $interval) {
                values {
                    timestamp
                    value
                }
            }
        }
    }
`;

export const searchBoxQuery = graphql`
    query queries_SearchQuery($search: String!, $pageSize: Int!) {
        sites(
            search: $search
            pageSize: $pageSize
            orderBy: { field: Name, dir: Asc }
            onlyProvisioningStatuses: Active
            onlyWithValidBatteries: true
        ) {
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
            data {
                id
                name
                address {
                    state
                }
            }
        }
        devices(
            search: $search
            pageSize: $pageSize
            orderBy: { field: Name, dir: Asc }
            filters: { category: [PowerController] }
        ) {
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
            data {
                id
                name
                site {
                    id
                    name
                    address {
                        state
                    }
                }
            }
        }
    }
`;

export const deviceAverageTempQuery = graphql`
    query queries_DeviceAverageTempQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        device(id: $id) {
            name
            battery {
                metrics {
                    temperature(unit: Celsius, begin: $begin, end: $end, interval: $interval) {
                        values {
                            timestamp
                            value
                        }
                        trend {
                            percent
                        }
                    }
                }
            }
        }
    }
`;

export const deviceAverageEnergyQuery = graphql`
    query queries_DeviceAverageEnergyQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!, $interval: Duration!) {
        device(id: $id) {
            name
            battery {
                metrics {
                    energy(unit: KiloWattHour, begin: $begin, end: $end, interval: $interval) {
                        values {
                            timestamp
                            value
                        }
                        trend {
                            percent
                        }
                    }
                }
            }
        }
    }
`;

export const deviceTestSearchQuery = graphql`
    query queries_deviceTestSearchQuery($id: ID!, $testName: String = "", $pageSize: Int!) {
        device(id: $id) {
            tests(search: $testName, pageSize: $pageSize) {
                data {
                    task {
                        name
                    }
                }
            }
        }
    }
`;

export const batteryTestCardsQuery = graphql`
    query queries_batteryTestCardsQuery(
        $id: ID!
        $testName: String
        $status: [BatteryTestState!]
        $page: Int!
        $pageSize: Int = 3
        $pageCount: Int
        $testFilters: DeviceTestFilter
        $testOrdering: DeviceTestOrdering = { field: StartTime, dir: Desc }
    ) {
        device(id: $id) {
            id
            name
            site {
                id
            }
            overallTests: tests {
                total
            }
            monitorOnly
            type {
                features {
                    battery {
                        testing
                    }
                }
            }
            tests(
                search: $testName
                page: $page
                pageSize: $pageSize
                pageCount: $pageCount
                state: $status
                orderBy: $testOrdering
                filters: $testFilters
            ) {
                total
                pageInfo {
                    hasNext
                    hasPrevious
                    page
                    total
                }
                data {
                    ...BatteryTestResultCard_test
                    id
                    state
                    commencedTime
                    completedTime
                    batteryStrings {
                        string
                    }
                    device {
                        name
                    }
                    task {
                        ...BatteryTestCard_data
                        id
                        name
                        type
                        createdTime
                        schedule {
                            time
                        }
                        creator {
                            name
                            email
                            username
                        }
                    }
                    cause
                }
            }
        }
    }
`;
