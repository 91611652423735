/**
 * @generated SignedSource<<4260c8be50491d0e7f1f18f22d303a01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryTestFailReason = "BatteryHealth" | "BatteryHealthUnknown" | "Busy" | "DeviceRemoved" | "DidNotStart" | "InsufficientReserveTime" | "InternalError" | "NoDischarge" | "NotEnoughData" | "SmartStartTimeout" | "SystemAbort" | "TestLockout" | "ThresholdReached" | "TooShort" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type EstimatedValueType = "Maximum" | "Minimum" | "Standard" | "%future added value";
export type MissingResultExplanation = "InternalError" | "MissingBattery" | "MissingConfiguration" | "MissingDataPoints" | "MissingDischargeTables" | "MissingReserveTime" | "MissingThresholdInformation" | "NoCDF" | "NoDischarge" | "TooShort" | "TooUncertain" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ResultView_test$data = {
  readonly batteryTypes: ReadonlyArray<{
    readonly dischargeTables: ReadonlyArray<{
      readonly endOfDischargeVoltage: number;
    }>;
    readonly minimumStateOfHealth: number;
  }>;
  readonly device: {
    readonly battery: {
      readonly allowedVoltage: {
        readonly minimum: number | null;
      };
      readonly quickTestFailThreshold: number | null;
      readonly reserveTime: number | null;
      readonly strings: {
        readonly strings: ReadonlyArray<{
          readonly cellsPerString: number;
        }>;
      };
    };
  };
  readonly estimatedCapacity: {
    readonly confidenceInterval?: {
      readonly lower: number;
      readonly upper: number;
    } | null;
    readonly reasonDescription?: string;
    readonly type?: EstimatedValueType;
    readonly value?: number;
  } | null;
  readonly estimatedReserveTime: {
    readonly confidenceInterval?: {
      readonly lower: number;
      readonly upper: number;
    } | null;
    readonly reasonDescription?: string;
    readonly type?: EstimatedValueType;
    readonly value?: number;
  } | null;
  readonly estimatedStateOfHealth: {
    readonly confidenceInterval?: {
      readonly lower: number;
      readonly upper: number;
    } | null;
    readonly reasonDescription?: string;
    readonly type?: EstimatedValueType;
    readonly value?: number;
  } | null;
  readonly failReason: BatteryTestFailReason | null;
  readonly originalCapacity: number | null;
  readonly quickCheckResult: {
    readonly passed: boolean | null;
    readonly reason: MissingResultExplanation | null;
  } | null;
  readonly state: BatteryTestState;
  readonly task: {
    readonly settings: {
      readonly threshold?: number | null;
    };
  } | null;
  readonly " $fragmentType": "ResultView_test";
};
export type ResultView_test$key = {
  readonly " $data"?: ResultView_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResultView_test">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ConfidenceInterval",
        "kind": "LinkedField",
        "name": "confidenceInterval",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lower",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "upper",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "EstimatedValue",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reasonDescription",
        "storageKey": null
      }
    ],
    "type": "MissingEstimatedValue",
    "abstractKey": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "AmpHour"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Minute"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResultView_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "failReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryType",
      "kind": "LinkedField",
      "name": "batteryTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimumStateOfHealth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DischargeTable",
          "kind": "LinkedField",
          "name": "dischargeTables",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endOfDischargeVoltage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "estimatedStateOfHealth",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "originalCapacity",
      "storageKey": "originalCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": null,
      "kind": "LinkedField",
      "name": "estimatedCapacity",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "estimatedCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBattery",
          "kind": "LinkedField",
          "name": "battery",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v2/*: any*/),
              "kind": "ScalarField",
              "name": "reserveTime",
              "storageKey": "reserveTime(unit:\"Minute\")"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "VoltageRange",
              "kind": "LinkedField",
              "name": "allowedVoltage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minimum",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quickTestFailThreshold",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DeviceBatteryStrings",
              "kind": "LinkedField",
              "name": "strings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DeviceBatteryString",
                  "kind": "LinkedField",
                  "name": "strings",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cellsPerString",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": null,
      "kind": "LinkedField",
      "name": "estimatedReserveTime",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "estimatedReserveTime(unit:\"Minute\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickTestResult",
      "kind": "LinkedField",
      "name": "quickCheckResult",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "passed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "threshold",
                  "storageKey": null
                }
              ],
              "type": "BatteryTestTypeQuick",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "68221e63386a01c20463d96950db3f53";

export default node;
