/**
 * @generated SignedSource<<6fc72bd10365eb5b19c327350466ef07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeastReliableSites$data = {
  readonly data: ReadonlyArray<{
    readonly acPower: {
      readonly reliability: {
        readonly mtbf: {
          readonly current: number | null;
        };
        readonly mttr: {
          readonly current: number | null;
        };
      };
    };
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "LeastReliableSites";
};
export type LeastReliableSites$key = {
  readonly " $data"?: LeastReliableSites$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeastReliableSites">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeastReliableSites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteACPower",
          "kind": "LinkedField",
          "name": "acPower",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "timeRange",
                  "variableName": "timeRange"
                },
                {
                  "kind": "Literal",
                  "name": "unit",
                  "value": "Second"
                }
              ],
              "concreteType": "SiteAcPowerReliabilityMetrics",
              "kind": "LinkedField",
              "name": "reliability",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReliabilityMetric",
                  "kind": "LinkedField",
                  "name": "mttr",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ReliabilityMetric",
                  "kind": "LinkedField",
                  "name": "mtbf",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedSites",
  "abstractKey": null
};
})();

(node as any).hash = "5e0746df81ae4c391a17aaaa62bf083c";

export default node;
