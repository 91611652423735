/**
 * @generated SignedSource<<97c3cffa5f5be56b215988088462b355>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationWatchErrorType = "InvalidEntityId" | "InvalidNotificationType" | "TooManyWatches" | "%future added value";
export type watchControlStopWatchingMutation$variables = {
  id: string;
};
export type watchControlStopWatchingMutation$data = {
  readonly unwatchForNotifications: {
    readonly problems: ReadonlyArray<NotificationWatchErrorType> | null;
    readonly success: boolean;
  } | null;
};
export type watchControlStopWatchingMutation = {
  response: watchControlStopWatchingMutation$data;
  variables: watchControlStopWatchingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entity",
        "variableName": "id"
      }
    ],
    "concreteType": "NotificationWatchResult",
    "kind": "LinkedField",
    "name": "unwatchForNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "watchControlStopWatchingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "watchControlStopWatchingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ebcaeebd229d4d33fdbb8abf1495c51d",
    "id": null,
    "metadata": {},
    "name": "watchControlStopWatchingMutation",
    "operationKind": "mutation",
    "text": "mutation watchControlStopWatchingMutation(\n  $id: ID!\n) {\n  unwatchForNotifications(entity: $id) {\n    success\n    problems\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b927ba8f6bb2dc702f6b7a314944420";

export default node;
