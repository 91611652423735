/**
 * @generated SignedSource<<cfb26bd96b1d39e3ee5e7320eb9a6ed4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type DataDisplaySingleQuery$variables = {
  begin?: string | null;
  end?: string | null;
  includeRelativeTests?: boolean | null;
  interval?: string | null;
  relativeTestIds?: ReadonlyArray<string> | null;
  testId: string;
  unitTemperature?: UnitTemperature | null;
};
export type DataDisplaySingleQuery$data = {
  readonly batteryTestResult: {
    readonly abortedTime: string | null;
    readonly aggregatedCurrent: ReadonlyArray<{
      readonly average: number | null;
      readonly offset: string;
      readonly timestamp: string;
    }> | null;
    readonly aggregatedPower: ReadonlyArray<{
      readonly average: number | null;
      readonly offset: string;
      readonly timestamp: string;
    }> | null;
    readonly aggregatedTemperature: ReadonlyArray<{
      readonly average: number | null;
      readonly offset: string;
      readonly timestamp: string;
    }> | null;
    readonly aggregatedVoltage: ReadonlyArray<{
      readonly average: number | null;
      readonly offset: string;
      readonly timestamp: string;
    }> | null;
    readonly commencedTime: string | null;
    readonly completedTime: string | null;
    readonly device?: {
      readonly tests: {
        readonly data: ReadonlyArray<{
          readonly aggregatedCurrent: ReadonlyArray<{
            readonly average: number | null;
            readonly offset: string;
            readonly timestamp: string;
          }> | null;
          readonly aggregatedPower: ReadonlyArray<{
            readonly average: number | null;
            readonly offset: string;
            readonly timestamp: string;
          }> | null;
          readonly aggregatedTemperature: ReadonlyArray<{
            readonly average: number | null;
            readonly offset: string;
            readonly timestamp: string;
          }> | null;
          readonly aggregatedVoltage: ReadonlyArray<{
            readonly average: number | null;
            readonly offset: string;
            readonly timestamp: string;
          }> | null;
          readonly cause: BatteryTestReason;
          readonly commencedTime: string | null;
          readonly completedTime: string | null;
          readonly id: string;
          readonly task: {
            readonly name: string | null;
          } | null;
        }>;
      };
    };
    readonly state: BatteryTestState;
  } | null;
};
export type DataDisplaySingleQuery = {
  response: DataDisplaySingleQuery$data;
  variables: DataDisplaySingleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "includeRelativeTests"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relativeTestIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "testId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unitTemperature"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "testId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abortedTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v12 = {
  "kind": "Variable",
  "name": "begin",
  "variableName": "begin"
},
v13 = {
  "kind": "Variable",
  "name": "end",
  "variableName": "end"
},
v14 = {
  "kind": "Variable",
  "name": "interval",
  "variableName": "interval"
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "timestamp",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "offset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": [
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Volt"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedVoltage",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": [
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Variable",
      "name": "unit",
      "variableName": "unitTemperature"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedTemperature",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": [
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Amp"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedCurrent",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Watt"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedPower",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "relativeTestIds"
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cause",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DataDisplaySingleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "condition": "includeRelativeTests",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "PaginatedDeviceBatteryTestResults",
                    "kind": "LinkedField",
                    "name": "tests",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryTestResults",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v21/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BatteryTest",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": [
                              (v22/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "DataDisplaySingleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "condition": "includeRelativeTests",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "PaginatedDeviceBatteryTestResults",
                    "kind": "LinkedField",
                    "name": "tests",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryTestResults",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": true,
                        "selections": [
                          (v21/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BatteryTest",
                            "kind": "LinkedField",
                            "name": "task",
                            "plural": false,
                            "selections": [
                              (v22/*: any*/),
                              (v21/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3273e3533d56c567c86ab53346fc6318",
    "id": null,
    "metadata": {},
    "name": "DataDisplaySingleQuery",
    "operationKind": "query",
    "text": "query DataDisplaySingleQuery(\n  $testId: ID!\n  $interval: Duration\n  $begin: Duration\n  $end: Duration\n  $relativeTestIds: [String!]\n  $includeRelativeTests: Boolean = false\n  $unitTemperature: UnitTemperature\n) {\n  batteryTestResult(id: $testId) {\n    commencedTime\n    completedTime\n    abortedTime\n    state\n    aggregatedVoltage(unit: Volt, interval: $interval, begin: $begin, end: $end) {\n      timestamp\n      offset\n      average\n    }\n    aggregatedTemperature(unit: $unitTemperature, interval: $interval, begin: $begin, end: $end) {\n      timestamp\n      offset\n      average\n    }\n    aggregatedCurrent(unit: Amp, interval: $interval, begin: $begin, end: $end) {\n      timestamp\n      offset\n      average\n    }\n    aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {\n      timestamp\n      offset\n      average\n    }\n    device @include(if: $includeRelativeTests) {\n      tests(ids: $relativeTestIds) {\n        data {\n          id\n          commencedTime\n          completedTime\n          task {\n            name\n            id\n          }\n          cause\n          aggregatedVoltage(unit: Volt, interval: $interval, begin: $begin, end: $end) {\n            timestamp\n            offset\n            average\n          }\n          aggregatedTemperature(unit: $unitTemperature, interval: $interval, begin: $begin, end: $end) {\n            timestamp\n            offset\n            average\n          }\n          aggregatedCurrent(unit: Amp, interval: $interval, begin: $begin, end: $end) {\n            timestamp\n            offset\n            average\n          }\n          aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {\n            timestamp\n            offset\n            average\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0a95e86a57cbab49540c7a950f07099";

export default node;
