/**
 * @generated SignedSource<<841bb7e651ec80b76350439589b5175c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type MetricUnit = "Amp" | "AmpHour" | "Bar" | "Celsius" | "Degree" | "Fahrenheit" | "Hertz" | "Hour" | "Kelvin" | "KiloPascal" | "Litre" | "LitrePerHour" | "Minute" | "PSI" | "Pascal" | "Percent" | "RPM" | "Second" | "USGallon" | "USGallonPerHour" | "Volt" | "VoltAmp" | "VoltAmpReactive" | "Watt" | "WattHour" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SelectMetricsPaneFragment$data = {
  readonly metricTypes: ReadonlyArray<{
    readonly displayName: string;
    readonly metric: string;
    readonly unit: MetricUnit | null;
  }>;
  readonly " $fragmentType": "SelectMetricsPaneFragment";
};
export type SelectMetricsPaneFragment$key = {
  readonly " $data"?: SelectMetricsPaneFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectMetricsPaneFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "deviceIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectMetricsPaneFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aggregateable",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "devices",
          "variableName": "deviceIds"
        }
      ],
      "concreteType": "DefinedMetric",
      "kind": "LinkedField",
      "name": "metricTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "metric",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7a44f3560741777bbfe90bf1871a0e22";

export default node;
