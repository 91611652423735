import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { RouteExtraData } from 'lib/route-helpers';

import { NotificationSettings } from './notification-settings/NotificationSettings';
import { loadNotificationSettingsContent } from './notification-settings/NotificationSettingsContent';
import { PersonalSettings } from './personal-settings/PersonalSettings';
import { loadPersonalSettingsContent } from './personal-settings/PersonalSettingsContent';

const Routes: RouteObject[] = [
    {
        path: 'account',
        handle: {
            title: 'Account',
        } as RouteExtraData,
        children: [
            {
                index: true,
                element: <Navigate to='settings' replace />,
            },
            {
                path: 'settings',
                handle: {
                    title: 'Personal Settings',
                    altTitle: 'Personal Settings',
                    description: 'View and manage your personal settings',
                } as RouteExtraData,
                element: <PersonalSettings />,
                loader: loadPersonalSettingsContent,
            },
            {
                path: 'notifications',
                handle: {
                    title: 'Notifications',
                } as RouteExtraData,
                children: [
                    {
                        index: true,
                        element: <Navigate to='settings' replace />, // FIXME: Rediret to /history instead
                    },
                    // TODO: path 'history' for notifications
                    {
                        path: 'settings',
                        handle: {
                            title: 'Settings',
                            altTitle: 'Notification Settings',
                            description: 'View and manage your notification settings',
                        } as RouteExtraData,
                        element: <NotificationSettings />,
                        loader: loadNotificationSettingsContent,
                    },
                ],
            },
        ],
    },
];

export default Routes;
