import React, { FC } from 'react';
import { useFragment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import {
    AmmeterIcon,
    BarDataType,
    Button,
    Link,
    ListView,
    Menu,
    StackedHorizontalBar,
    StraightAngleGauge,
    Theme,
    ThermometerIcon,
    VoltmeterIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration from 'humanize-duration';
import { useCurrentUserUnitsPref, useUserPermissions } from 'lib/auth';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { MenuItemGroup } from 'lib/menu';
import { round } from 'lib/number';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { formatValueWithString, formatValueWithUnit } from 'lib/units';
import { Duration } from 'luxon';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { Operation } from '../../../../explore/types';
import { batteryStatusToIcon, batteryStatusToTextColor } from '../../lib';
import { BatteryStatus, DeviceBatteries_device$key } from './__generated__/DeviceBatteries_device.graphql';
import { BatteryStringDisplay } from './components';
import { BatteryTestRow } from './components/BatteryTestRow';
import style from './style.module.css';

// Just using 3 hours as a default
const DefaultReserveTime = 3;

const BatteryStatusToColor: Record<BatteryStatus, string> = {
    FloatCharging: 'bg-eggplantRegular',
    BoostCharging: 'bg-pineRegular',
    Discharging: 'bg-coralRegular',
    Idle: 'bg-coralLight',
    Unknown: 'bg-eggplantExtraLight',
    Missing: 'bg-grayRegular',
    Charging: 'bg-pineRegular',
    Disconnected: 'bg-grayRegular',
    '%future added value': 'bg-grayRegular',
};

export interface DeviceBatteriesProps {
    device: DeviceBatteries_device$key;
    deviceId: string;
    siteId: string;
    timeRange: Duration;
}

export const DeviceBatteries: FC<DeviceBatteriesProps> = ({ device, deviceId, siteId, timeRange }) => {
    const { hasAssetsWrite, hasTasksRead, hasTasksWrite, hasAssetsRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);
    const unitPreferences = useCurrentUserUnitsPref();

    const stringRows = data.battery?.strings.strings ?? [];
    const batteryTestRows = data.tests?.data ?? [];

    let averageAge;
    if (data.battery?.averageAge !== null) {
        averageAge = Duration.fromISO(data.battery?.averageAge).as('years');
        averageAge = round(averageAge, 1);
    } else {
        averageAge = null;
    }

    const totalTimeInHours = timeRange.as('hours');

    const statusBarData: BarDataType[] = [];
    if (data.battery?.metrics.statusBreakdown) {
        for (const status of data.battery.metrics.statusBreakdown) {
            if (status.time == null || status.time <= 0) {
                continue;
            }
            statusBarData.push({
                label: batteryStatusToString(status.status),
                value: status.time,
                bgClass: BatteryStatusToColor[status.status],
            });
        }
    }

    const statusBarDataHoursCount = statusBarData.reduce((acc, curr) => acc + curr.value, 0);

    // if we don't have 24 hours of data, add a "Device offline" bar
    if (Math.round(statusBarDataHoursCount) < 24) {
        statusBarData.push({
            label: 'Device offline',
            value: totalTimeInHours - statusBarDataHoursCount,
            bgClass: 'bg-coralRegular',
        });
    }

    let voltageMetric: string;
    let currentMetric: string;
    let temperatureMetric: string;
    let statusMetric: string;
    let totalReserveTime;
    if (data.battery?.metrics?.latestVoltage && data.health !== 'Offline') {
        voltageMetric = formatValueWithString(numberToLocaleString(data.battery.metrics.latestVoltage), 'V');
    } else {
        voltageMetric = formatValueWithString('-', 'V');
    }
    if (data.battery?.metrics?.latestCurrent && data.health !== 'Offline') {
        currentMetric = formatValueWithString(numberToLocaleString(data.battery.metrics.latestCurrent), 'A');
    } else {
        currentMetric = formatValueWithString('-', 'A');
    }
    if (data.battery?.metrics?.latestTemperature && data.health !== 'Offline') {
        temperatureMetric = formatValueWithUnit(
            numberToLocaleString(data.battery.metrics.latestTemperature),
            unitPreferences.temperature
        );
    } else {
        temperatureMetric = formatValueWithUnit('-', unitPreferences.temperature);
    }
    if (data.battery?.metrics?.latestStatus) {
        statusMetric = `${batteryStatusToString(data.battery?.metrics?.latestStatus)}`;
    } else {
        statusMetric = '-';
    }
    if (data.battery?.reserveTime) {
        totalReserveTime = numberToLocaleString(data.battery.reserveTime, 0);
        totalReserveTime = `out of ${totalReserveTime} hours`;
    } else {
        totalReserveTime = '-';
    }

    const designReserveTimeForThresholds = data.battery?.reserveTime ?? DefaultReserveTime;

    const badReserveTimeCutoff = designReserveTimeForThresholds * 0.9;
    const goodReserveTimeCutoff = designReserveTimeForThresholds * 1.1;
    const minReserveTimeCutoff = 0;
    const maxReserveTimeCutoff = designReserveTimeForThresholds * 2;

    let estimatedReserveTime: number | null;
    if (data.battery?.estimatedReserveTime) {
        estimatedReserveTime = round(data.battery.estimatedReserveTime, 1);
    } else {
        estimatedReserveTime = null;
    }

    const supportBatteryTesting = data.type.features.battery.testing;

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-eggplantRegular text-xl'>Batteries</div>

                <div className='flex flex-col justify-start items-end flex-grow'>
                    <div
                        className={batteryStatusToTextColor(
                            data.health === 'Offline' ? 'Offline' : data.battery?.metrics?.latestStatus
                        )}
                    >
                        <IconWithStatus
                            label={data.health === 'Offline' ? 'Device offline' : statusMetric}
                            icon={batteryStatusToIcon(
                                data.health === 'Offline' ? 'Offline' : data.battery?.metrics?.latestStatus
                            )}
                        />
                    </div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        groups={[
                            { key: MenuItemGroup.Assets, title: MenuItemGroup.Assets },
                            { key: MenuItemGroup.Reports, title: MenuItemGroup.Reports },
                        ]}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                disabled: !hasAssetsRead,
                                group: MenuItemGroup.Assets,
                            },
                            {
                                name: 'Schedule test',
                                onClick: () =>
                                    navigate({ pathname: Paths.TestsScheduleTest, search: { device: deviceId } }),
                                disabled: !hasTasksWrite || data.monitorOnly || !supportBatteryTesting,
                                // TODO: Add disabled message when supported - should either tell user that they dont have permission or device is in monitorOnly mode
                            },
                            {
                                name: 'Battery report',
                                onClick: () =>
                                    navigate({
                                        pathname: Paths.ReportBatteriesViewSiteDevice,
                                        params: { siteId, deviceId },
                                    }),
                                group: MenuItemGroup.Reports,
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>

            <StackedHorizontalBar
                data={statusBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }
                    return humanizeDuration(Duration.fromObject({ hours: value }).as('milliseconds'), {
                        largest: 1,
                        round: true,
                        units: ['h', 'm'],
                    });
                }}
            />

            <div className='flex flex-row justify-start gap-3 mb-4'>
                <IconWithStatus
                    title='Battery Voltage'
                    icon={<VoltmeterIcon />}
                    label={voltageMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryVoltage', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Battery Current'
                    icon={<AmmeterIcon />}
                    label={currentMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryCurrent', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Battery Temperature'
                    icon={<ThermometerIcon />}
                    label={temperatureMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'BatteryTemperature', op: Operation.Average })}
                />
            </div>

            <div className='grid grid-cols-2'>
                <div className='col-start-1'>
                    <div className={style.chart_title}>Reserve Time</div>
                    <div className={style.chart_container}>
                        <StraightAngleGauge
                            thresholds={[badReserveTimeCutoff, goodReserveTimeCutoff]}
                            value={estimatedReserveTime}
                            unit='hours'
                            subtitle={totalReserveTime}
                            min={minReserveTimeCutoff}
                            max={maxReserveTimeCutoff}
                        ></StraightAngleGauge>
                    </div>
                </div>
                <div className='col-start-2'>
                    <div className={style.chart_title}>Average Age</div>
                    <div className={style.chart_container}>
                        <StraightAngleGauge
                            thresholds={[7, 10]}
                            regionColours={[Theme.pineRegular, Theme.mustardRegular, Theme.coralRegular]}
                            value={averageAge}
                            unit={'years'}
                            min={0}
                            max={15}
                        ></StraightAngleGauge>
                    </div>
                </div>
            </div>

            <ListView
                title='Strings'
                view='list'
                contentClassName='h-96'
                scroll
                subtitle={getStringsFrameSubtitle(data.battery?.strings.count)}
            >
                <BatteryStringDisplay stringRows={stringRows} deviceId={deviceId} />
            </ListView>

            <div>
                <ListView
                    title='Tests'
                    view='list'
                    contentClassName='h-96'
                    scroll
                    subtitle={getTasksFrameSubtitle(data.testsCount?.total, hasTasksRead)}
                >
                    {batteryTestRows.map(batteryTestRow => (
                        <BatteryTestRow key={batteryTestRow.id} batteryTestRow={batteryTestRow} />
                    ))}

                    {!hasTasksRead && (
                        <>
                            <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                            <div className='col-span-4 grid place-items-center space-y-4'>
                                <span>Insufficient permission to view tests</span>
                            </div>
                            <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        </>
                    )}

                    {batteryTestRows.length === 0 && hasTasksRead && (
                        <>
                            <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                            <div className='col-span-4 grid place-items-center space-y-4 text-center'>
                                <span>No tests have been run for this device</span>
                                <Button
                                    buttonText={'Schedule a test'}
                                    onClick={() =>
                                        navigate({
                                            pathname: Paths.TestsScheduleTest,
                                            search: { device: deviceId },
                                        })
                                    }
                                    size='small'
                                    variant={'primary'}
                                    disabled={!hasTasksWrite || stringRows.length === 0}
                                    disabledMessage={
                                        !hasTasksWrite
                                            ? 'You do not have permission to schedule tests'
                                            : 'No battery strings configured'
                                    }
                                />
                            </div>
                            <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        </>
                    )}
                </ListView>
                {hasTasksRead && (
                    <div className='float-left pt-3'>
                        <Link
                            to={generatePath(Paths.ReportBatteryTestsByDevice, { siteId, deviceId })}
                            className='font-light hover:underline'
                        >
                            View all tests
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceBatteries_device on Device
    @argumentDefinitions(hasTasksRead: { type: "Boolean!" }, unitTemperature: { type: "UnitTemperature" }) {
        monitorOnly
        health
        type {
            features {
                battery {
                    testing
                }
            }
        }
        battery {
            metrics {
                statusBreakdown(begin: $begin, end: $end) {
                    status
                    time(unit: Hour)
                }
                latestStatus
                latestVoltage
                latestCurrent
                latestTemperature(unit: $unitTemperature)
            }
            strings {
                count
                strings {
                    id
                    ...StringRow_data
                }
            }
            averageAge
            estimatedReserveTime(unit: Hour)
            reserveTime(unit: Hour)
        }
        tests(pageSize: 10, orderBy: { field: StartTime, dir: Desc }) @include(if: $hasTasksRead) {
            data {
                id
                ...BatteryTestRow_data
            }
        }
        testsCount: tests @include(if: $hasTasksRead) {
            total
        }
    }
`;

function getTasksFrameSubtitle(testCount: number | undefined, hasTasksRead: boolean): string {
    if (!hasTasksRead) {
        return '';
    }

    if (testCount) {
        if (testCount === 1) {
            return `1 test`;
        } else {
            return `${testCount} tests`;
        }
    } else {
        return 'No tests';
    }
}

function getStringsFrameSubtitle(count: number | undefined): string {
    if (count) {
        if (count === 1) {
            return `1 string`;
        } else {
            return `${count} strings`;
        }
    }
    return '';
}
