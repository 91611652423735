import { FC } from 'react';
import React from 'react';

import {
    AccataIcon,
    BatteryChargingIcon,
    CircleHelpIcon,
    GeneratorIcon,
    ReportsIcon,
    Tooltip,
} from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import classNamesBind from 'classnames/bind';
import {
    NotificationPane_data$data,
    NotificationType,
} from 'components/ThemedPage/notification/__generated__/NotificationPane_data.graphql';
import humanizeDuration from 'humanize-duration';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { DateTime } from 'luxon';

import { NotificationAction, NotificationActionButton } from './NotificationAction';
import style from './style.module.css';

const classNames = classNamesBind.bind(style);

type Notification = NotificationPane_data$data['data'][number];

export interface NotificationEntryProps {
    notification: Notification;
    onClick: () => void;
    extraContent?: React.ReactNode;
    actions?: NotificationAction[];
}

const DateThreshold = DateTime.local().startOf('day').minus({ days: 1 });

export const NotificationEntry: FC<NotificationEntryProps> = ({ notification, extraContent, actions, onClick }) => {
    const absoluteTimestamp = getDateTimeFormat(notification.timestamp);
    const relativeTimestamp = humanizeDuration(
        DateTime.local().diff(DateTime.fromISO(notification.timestamp)).as('milliseconds'),
        {
            largest: 1,
            round: true,
            units: ['h', 'm'], // Only cover up to the last 2 days
        }
    );

    // NOTE: use relative date if its today or yesterday, otherwise use absolute date
    const displayTimestamp =
        DateTime.fromISO(notification.timestamp) > DateThreshold ? `${relativeTimestamp} ago` : absoluteTimestamp;

    const icon = getNotificationIcon(notification.type);
    const actionButtonSize = actions?.length === 1 ? 'medium' : 'small';

    const title = parseNotificationMessage(notification.message);

    return (
        <div
            role='button'
            onClick={onClick}
            className={classNames('container', notification.unread ? 'unread' : 'read')}
        >
            <div className={classNames('container_header')}>
                <div className={classNames('header_icon')}>{icon}</div>
                <Tooltip content={notification.message} overflowOnly>
                    <span className={classNames('header_title')}>{title}</span>
                </Tooltip>
            </div>
            {extraContent}
            <div className={classNames('container_footer')}>
                <Tooltip content={absoluteTimestamp}>
                    <div className={classNames('time_display')}>{displayTimestamp}</div>
                </Tooltip>
                {actions && (
                    <div className={classNames('actions')}>
                        {actions.map((action, index) => (
                            <NotificationActionButton key={index} size={actionButtonSize} {...action} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
        case 'BatteryDischarge':
            return <ReportsIcon />;
        case 'GeneratorRun':
            return <GeneratorIcon />;
        case 'ScheduledBatteryTestComplete':
            return <BatteryChargingIcon />;
        case 'ProductUpdate':
            return <AccataIcon />;
        default:
            captureMessage(`Unhandled notification type: ${type}`, context => {
                context.setTag('Function', 'getNotificationIcon');
                context.setTag('Notification Type', type);
                return context;
            });
            return <CircleHelpIcon />;
    }
};

/**
 *
 * @param message The message to parse
 * Will wrap the message within a <span> tag and <bold> tag for the ones wrapped within `**[word]**`
 */
export const parseNotificationMessage = (message: string) => {
    const parts = message.split('**');
    return parts.map((part, index) => {
        if (index % 2 === 1) {
            return <b key={index}>{part}</b>;
        }
        return <span key={index}>{part}</span>;
    });
};
