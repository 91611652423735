/**
 * @generated SignedSource<<7023f011c38b256c6d2e1e8f9eb06fe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type LoadContentQuery$variables = {
  end: string;
  id: string;
  start: string;
};
export type LoadContentQuery$data = {
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly health: DeviceHealth | null;
        readonly id: string;
        readonly lastOnline: string | null;
        readonly lastUpdate: string | null;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeviceLoad_device">;
      } | null;
    } | null;
    readonly health: DeviceHealth | null;
    readonly id: string;
    readonly lastOnline: string | null;
    readonly lastUpdate: string | null;
    readonly name: string;
    readonly site: {
      readonly id: string;
      readonly powerControllers: {
        readonly data: ReadonlyArray<{
          readonly id: string;
        }>;
      };
    };
    readonly type: {
      readonly category: DeviceCategory;
      readonly displayName: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeviceLoad_device">;
  } | null;
};
export type LoadContentQuery = {
  response: LoadContentQuery$data;
  variables: LoadContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "health",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": "powerControllers",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "category": [
              "PowerController"
            ]
          }
        }
      ],
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
    }
  ],
  "storageKey": null
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeviceLoad_device"
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceLoad",
  "kind": "LinkedField",
  "name": "load",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "start"
        },
        {
          "kind": "Variable",
          "name": "to",
          "variableName": "end"
        }
      ],
      "concreteType": "DeviceLoadUptime",
      "kind": "LinkedField",
      "name": "uptime",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceLoadMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Amp"
            }
          ],
          "kind": "ScalarField",
          "name": "latestCurrent",
          "storageKey": "latestCurrent(unit:\"Amp\")"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Watt"
            }
          ],
          "kind": "ScalarField",
          "name": "latestPower",
          "storageKey": "latestPower(unit:\"Watt\")"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "begin",
              "variableName": "start"
            },
            {
              "kind": "Variable",
              "name": "end",
              "variableName": "end"
            },
            {
              "kind": "Literal",
              "name": "interval",
              "value": "PT15M"
            }
          ],
          "concreteType": "TimeSeriesMetrics",
          "kind": "LinkedField",
          "name": "power",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimestampMetric",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timestamp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e6ead626e723b927af7b3f12b83ae3b",
    "id": null,
    "metadata": {},
    "name": "LoadContentQuery",
    "operationKind": "query",
    "text": "query LoadContentQuery(\n  $id: ID!\n  $start: Timestamp!\n  $end: Timestamp!\n) {\n  device(id: $id) {\n    id\n    name\n    health\n    lastUpdate\n    lastOnline\n    type {\n      displayName\n      category\n      id\n    }\n    site {\n      id\n      powerControllers: devices(filters: {category: [PowerController]}) {\n        data {\n          id\n        }\n      }\n    }\n    ...DeviceLoad_device\n    dualPlaneCompanion {\n      device {\n        id\n        name\n        health\n        lastUpdate\n        lastOnline\n        type {\n          displayName\n          id\n        }\n        ...DeviceLoad_device\n      }\n    }\n  }\n}\n\nfragment DeviceLoad_device on Device {\n  health\n  load {\n    uptime(from: $start, to: $end) {\n      percentage\n    }\n    metrics {\n      latestVoltage\n      latestCurrent(unit: Amp)\n      latestPower(unit: Watt)\n      power(begin: $start, end: $end, interval: \"PT15M\") {\n        values {\n          timestamp\n          value\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab515f98eb6c600d08275fbd3b725cc3";

export default node;
