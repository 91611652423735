/**
 * @generated SignedSource<<6a9bc27354646d67ae221cf525fd287b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationSettingsContentQuery$variables = Record<PropertyKey, never>;
export type NotificationSettingsContentQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly " $fragmentSpreads": FragmentRefs<"DeliverySettingsFragment">;
    };
  };
};
export type NotificationSettingsContentQuery = {
  response: NotificationSettingsContentQuery$data;
  variables: NotificationSettingsContentQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationSettingsContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeliverySettingsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationSettingsContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationsSettings",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailDelay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNotificationDeliveryPreferences",
                    "kind": "LinkedField",
                    "name": "delivery",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "generatorRun",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "batteryDischarge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledBatteryTestComplete",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productUpdate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b4ca28875ed70485b454ad133bf52df",
    "id": null,
    "metadata": {},
    "name": "NotificationSettingsContentQuery",
    "operationKind": "query",
    "text": "query NotificationSettingsContentQuery {\n  currentUser {\n    preferences {\n      ...DeliverySettingsFragment\n    }\n    id\n  }\n}\n\nfragment DeliverySettingsFragment on UserPreferences {\n  notifications {\n    emailDelay\n    delivery {\n      generatorRun\n      batteryDischarge\n      scheduledBatteryTestComplete\n      productUpdate\n    }\n  }\n}\n"
  }
};

(node as any).hash = "acab080a4a002109dd59c35c2090dd6e";

export default node;
