import React from 'react';
import { FC } from 'react';

import { IconButton, IconButtonProps, Tooltip } from '@accesstel/pcm-ui';

export interface NotificationAction {
    /**
     * Text to display for the button
     * Will ONLY be displayed if there is only one action
     */
    text: string;
    /**
     * Icon to display for the button
     * Will ONLY be displayed if there is only one action
     */
    icon?: React.ReactNode;
    /**
     * Tooltip to display for the button
     * Will ONLY be displayed if there is only one action
     */
    tooltip?: string;
    /**
     * The callback function when the button is clicked
     */
    onClick: () => void;
}

interface NotificationActionButtonProps extends NotificationAction {
    /**
     * The size of the button
     * Small: Will use the IconButton without the text
     * Medium: Will use the IconButton with the text
     */
    size: 'small' | 'medium';
}

export const NotificationActionButton: FC<NotificationActionButtonProps> = ({ text, icon, tooltip, onClick, size }) => {
    const onButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        onClick();
    };

    const sharedProps: IconButtonProps = {
        onClick: onButtonClick,
        iconStroke: 'coral',
        size: 'small-medium',
        icon,
    };

    if (size === 'small') {
        return (
            <Tooltip content={tooltip ?? text}>
                <IconButton {...sharedProps} />
            </Tooltip>
        );
    }
    return <IconButton {...sharedProps} title={text} />;
};
