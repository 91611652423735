/**
 * @generated SignedSource<<15550a456d15fc244b61a075bbe41236>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type BatteriesContentQuery$variables = {
  begin: string;
  end: string;
  hasTasksRead: boolean;
  id: string;
  unitTemperature?: UnitTemperature | null;
};
export type BatteriesContentQuery$data = {
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly health: DeviceHealth | null;
        readonly id: string;
        readonly lastOnline: string | null;
        readonly lastUpdate: string | null;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeviceBatteries_device">;
      } | null;
    } | null;
    readonly health: DeviceHealth | null;
    readonly id: string;
    readonly lastOnline: string | null;
    readonly lastUpdate: string | null;
    readonly name: string;
    readonly site: {
      readonly id: string;
      readonly powerControllers: {
        readonly data: ReadonlyArray<{
          readonly id: string;
        }>;
      };
    };
    readonly type: {
      readonly category: DeviceCategory;
      readonly displayName: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeviceBatteries_device">;
  } | null;
};
export type BatteriesContentQuery = {
  response: BatteriesContentQuery$data;
  variables: BatteriesContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTasksRead"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unitTemperature"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "health",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": "powerControllers",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "category": [
              "PowerController"
            ]
          }
        }
      ],
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
    }
  ],
  "storageKey": null
},
v14 = {
  "args": [
    {
      "kind": "Variable",
      "name": "hasTasksRead",
      "variableName": "hasTasksRead"
    },
    {
      "kind": "Variable",
      "name": "unitTemperature",
      "variableName": "unitTemperature"
    }
  ],
  "kind": "FragmentSpread",
  "name": "DeviceBatteries_device"
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceTypeFeatures",
  "kind": "LinkedField",
  "name": "features",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceTypeBatteryFeatures",
      "kind": "LinkedField",
      "name": "battery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "testing",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monitorOnly",
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hour"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "begin",
              "variableName": "begin"
            },
            {
              "kind": "Variable",
              "name": "end",
              "variableName": "end"
            }
          ],
          "concreteType": "BatteryStatusTimeBreakdown",
          "kind": "LinkedField",
          "name": "statusBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v17/*: any*/),
              "kind": "ScalarField",
              "name": "time",
              "storageKey": "time(unit:\"Hour\")"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestCurrent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "unit",
              "variableName": "unitTemperature"
            }
          ],
          "kind": "ScalarField",
          "name": "latestTemperature",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBatteryStrings",
      "kind": "LinkedField",
      "name": "strings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryString",
          "kind": "LinkedField",
          "name": "strings",
          "plural": true,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "localId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "age",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cycles",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BatteryType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryCellDefinition",
                  "kind": "LinkedField",
                  "name": "cells",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "capacity",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v17/*: any*/),
      "kind": "ScalarField",
      "name": "estimatedReserveTime",
      "storageKey": "estimatedReserveTime(unit:\"Hour\")"
    },
    {
      "alias": null,
      "args": (v17/*: any*/),
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": "reserveTime(unit:\"Hour\")"
    }
  ],
  "storageKey": null
},
v19 = {
  "condition": "hasTasksRead",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "dir": "Desc",
            "field": "StartTime"
          }
        },
        {
          "kind": "Literal",
          "name": "pageSize",
          "value": 10
        }
      ],
      "concreteType": "PaginatedDeviceBatteryTestResults",
      "kind": "LinkedField",
      "name": "tests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryTestResults",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cause",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commencedTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BatteryTest",
              "kind": "LinkedField",
              "name": "task",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "overallState",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskSchedule",
                  "kind": "LinkedField",
                  "name": "schedule",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tests(orderBy:{\"dir\":\"Desc\",\"field\":\"StartTime\"},pageSize:10)"
    },
    {
      "alias": "testsCount",
      "args": null,
      "concreteType": "PaginatedDeviceBatteryTestResults",
      "kind": "LinkedField",
      "name": "tests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteriesContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "BatteriesContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v6/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v16/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v6/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f7ab57d13c5769a49378034576f7de8",
    "id": null,
    "metadata": {},
    "name": "BatteriesContentQuery",
    "operationKind": "query",
    "text": "query BatteriesContentQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n  $hasTasksRead: Boolean!\n  $unitTemperature: UnitTemperature\n) {\n  device(id: $id) {\n    id\n    name\n    health\n    lastUpdate\n    lastOnline\n    type {\n      displayName\n      category\n      id\n    }\n    site {\n      id\n      powerControllers: devices(filters: {category: [PowerController]}) {\n        data {\n          id\n        }\n      }\n    }\n    ...DeviceBatteries_device_TWaZX\n    dualPlaneCompanion {\n      device {\n        id\n        name\n        health\n        lastUpdate\n        lastOnline\n        type {\n          displayName\n          id\n        }\n        ...DeviceBatteries_device_TWaZX\n      }\n    }\n  }\n}\n\nfragment BatteryTestRow_data on DeviceBatteryTestResults {\n  id\n  state\n  name\n  cause\n  commencedTime\n  completedTime\n  task {\n    name\n    overallState\n    schedule {\n      time\n    }\n    id\n  }\n}\n\nfragment DeviceBatteries_device_TWaZX on Device {\n  monitorOnly\n  health\n  type {\n    features {\n      battery {\n        testing\n      }\n    }\n    id\n  }\n  battery {\n    metrics {\n      statusBreakdown(begin: $begin, end: $end) {\n        status\n        time(unit: Hour)\n      }\n      latestStatus\n      latestVoltage\n      latestCurrent\n      latestTemperature(unit: $unitTemperature)\n    }\n    strings {\n      count\n      strings {\n        id\n        ...StringRow_data\n      }\n    }\n    averageAge\n    estimatedReserveTime(unit: Hour)\n    reserveTime(unit: Hour)\n  }\n  tests(pageSize: 10, orderBy: {field: StartTime, dir: Desc}) @include(if: $hasTasksRead) {\n    data {\n      id\n      ...BatteryTestRow_data\n    }\n  }\n  testsCount: tests @include(if: $hasTasksRead) {\n    total\n  }\n}\n\nfragment StringRow_data on DeviceBatteryString {\n  id\n  localId\n  installDate\n  age\n  cycles\n  type {\n    manufacturer\n    model\n    cells {\n      capacity\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8309097a8fe9bddae5917cd37e8eab13";

export default node;
