/**
 * @generated SignedSource<<fa1726a23abda2881d30cd3516b029fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserPreferenceErrorType = "InvalidColumnId" | "InvalidNotificationsEmailDelay" | "InvalidTableId" | "InvalidTimezone" | "%future added value";
export type reducerUpdateTablePreferenceColumnsMutation$variables = {
  columns?: ReadonlyArray<string> | null;
  tableId: string;
};
export type reducerUpdateTablePreferenceColumnsMutation$data = {
  readonly updateCurrentUserTablePreferences: {
    readonly problems: ReadonlyArray<UpdateUserPreferenceErrorType> | null;
    readonly success: boolean;
  };
};
export type reducerUpdateTablePreferenceColumnsMutation = {
  response: reducerUpdateTablePreferenceColumnsMutation$data;
  variables: reducerUpdateTablePreferenceColumnsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columns"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tableId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "columns",
            "variableName": "columns"
          }
        ],
        "kind": "ObjectValue",
        "name": "preferences"
      },
      {
        "kind": "Variable",
        "name": "tableId",
        "variableName": "tableId"
      }
    ],
    "concreteType": "UpdateUserPreferenceResult",
    "kind": "LinkedField",
    "name": "updateCurrentUserTablePreferences",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reducerUpdateTablePreferenceColumnsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "reducerUpdateTablePreferenceColumnsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5a9a0bcd879d6a0079d01e9aa98c8f81",
    "id": null,
    "metadata": {},
    "name": "reducerUpdateTablePreferenceColumnsMutation",
    "operationKind": "mutation",
    "text": "mutation reducerUpdateTablePreferenceColumnsMutation(\n  $tableId: ID!\n  $columns: [ID!]\n) {\n  updateCurrentUserTablePreferences(tableId: $tableId, preferences: {columns: $columns}) {\n    success\n    problems\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb1733410280e5210ac9cbc91382a8f5";

export default node;
