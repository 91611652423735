import React, { FC } from 'react';

import { Button, Dropdown, PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';

import { useDocumentTitle } from '../../../../components';
import { DefaultTimeRanges, useTimeRange } from '../../common';
import style from './Overview.module.css';
import { BatteryOverviewQuery } from './__generated__/BatteryOverviewQuery.graphql';
import { AverageBatteryCurrent } from './components/AverageBatteryCurrent';
import { AverageEnergyColumn } from './components/AverageEnergyColumn';
import { AverageEnergySparkline } from './components/AverageEnergySparkline';
import { AverageTemperatureSparkline } from './components/AverageTemperatureSparkline';
import { BatteryCapacityHisto } from './components/BatteryCapacityHisto';
import { BatteryTimeRemainingHisto } from './components/BatteryTimeRemainingHisto';
import { CurrentCapacityDoughnut } from './components/CurrentCapacityDoughnut';
import { CurrentStatusDoughnut } from './components/CurrentStatusDoughnut';
import { ReportSearch } from './components/ReportSearch';

export const BatteryOverview: FC = () => {
    const [timeRange, timeRangeMenuItems] = useTimeRange(DefaultTimeRanges[0]);
    useDocumentTitle('Reports - Batteries');

    const {
        data: props,
        error,
        retry,
    } = useQuery<BatteryOverviewQuery>(
        graphql`
            query BatteryOverviewQuery {
                ...CurrentStatusDoughnut
                ...CurrentCapacityDoughnut

                batteryMetrics {
                    remainingCapacityHistogram(unit: AmpHour, interval: 100, range: { from: 0, to: 2000 }) {
                        ...BatteryCapacityHisto_remainingCapacityHistogram
                    }
                    timeRemainingHistogram(unit: Hour, interval: 1, range: { from: 0, to: 24 }) {
                        ...BatteryTimeRemainingHisto_timeRemainingHistogram
                    }
                }
            }
        `,
        {}
    );

    return (
        <div className='space-y-6'>
            <div className='flex justify-between items-center'>
                <PageHeading value='Batteries' />
                <div className='flex'>
                    <div className='w-64 pr-4'>
                        <ReportSearch />
                    </div>
                    <Button buttonText='View all Sites' size='small' to={Paths.ReportBatterySiteList} />
                </div>
            </div>
            <div className={style.toolbar}>
                <Dropdown rightAlign title={timeRange.displayName} menuItems={timeRangeMenuItems} />
            </div>
            <div className={style.chart_layout}>
                <CurrentStatusDoughnut data={props ?? null} error={!!error} retry={retry} />
                <CurrentCapacityDoughnut data={props ?? null} error={!!error} retry={retry} />
                <AverageTemperatureSparkline timeRange={timeRange} />
                <AverageEnergySparkline timeRange={timeRange} />
                <BatteryTimeRemainingHisto fragmentRef={props?.batteryMetrics.timeRemainingHistogram ?? null} />
                <AverageBatteryCurrent timeRange={timeRange} />
                <BatteryCapacityHisto fragmentRef={props?.batteryMetrics.remainingCapacityHistogram ?? null} />
                <AverageEnergyColumn timeRange={timeRange} />
            </div>
        </div>
    );
};
