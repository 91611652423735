/**
 * @generated SignedSource<<92a18edd37033db14e4c1fe25425473a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidTPPL" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type TaskRepeat = "BiMonthly" | "Monthly" | "Never" | "Quarterly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TestParameterViewCombined_test$data = {
  readonly abortedBy: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly abortedTime: string | null;
  readonly batteryStrings: ReadonlyArray<{
    readonly originalCapacity: number | null;
    readonly string: number;
    readonly type: {
      readonly manufacturer: string;
      readonly model: string;
      readonly technology: BatteryTechnologyType;
    };
  }>;
  readonly batteryTypes: ReadonlyArray<{
    readonly manufacturer: string;
    readonly model: string;
    readonly technology: BatteryTechnologyType;
  }>;
  readonly cause: BatteryTestReason;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly battery: {
      readonly reserveTime: number | null;
    };
    readonly name: string;
  };
  readonly originalCapacity: number | null;
  readonly state: BatteryTestState;
  readonly task: {
    readonly completedTime: string | null;
    readonly createdTime: string;
    readonly creator: {
      readonly email: string | null;
      readonly name: string | null;
    };
    readonly schedule: {
      readonly repeat: TaskRepeat;
      readonly time: string;
    };
    readonly settings: {
      readonly endOfDischargeVoltage?: number | null;
      readonly maxDischarged?: number | null;
      readonly maxDuration?: number | null;
      readonly reserveTime?: number;
      readonly targets?: ReadonlyArray<{
        readonly batteryType: {
          readonly id: string;
          readonly manufacturer: string;
          readonly model: string;
        } | null;
        readonly endOfDischargeVoltage: number;
      }>;
      readonly threshold?: number | null;
    };
    readonly type: BatteryTestType;
  } | null;
  readonly " $fragmentType": "TestParameterViewCombined_test";
};
export type TestParameterViewCombined_test$key = {
  readonly " $data"?: TestParameterViewCombined_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestParameterViewCombined_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endOfDischargeVoltage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manufacturer",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "technology",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestParameterViewCombined_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSchedule",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "repeat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdTime",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryTestTypeCapacityEDOVTypePair",
                  "kind": "LinkedField",
                  "name": "targets",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BatteryType",
                      "kind": "LinkedField",
                      "name": "batteryType",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "BatteryTestTypeCapacity",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reserveTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "threshold",
                  "storageKey": null
                }
              ],
              "type": "BatteryTestTypeQuick",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxDuration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxDischarged",
                  "storageKey": null
                }
              ],
              "type": "BatteryTestTypeCustom",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "abortedBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryStringSnapshot",
      "kind": "LinkedField",
      "name": "batteryStrings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BatteryType",
          "kind": "LinkedField",
          "name": "type",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "string",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalCapacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryType",
      "kind": "LinkedField",
      "name": "batteryTypes",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBattery",
          "kind": "LinkedField",
          "name": "battery",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "unit",
                  "value": "Minute"
                }
              ],
              "kind": "ScalarField",
              "name": "reserveTime",
              "storageKey": "reserveTime(unit:\"Minute\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "AmpHour"
        }
      ],
      "kind": "ScalarField",
      "name": "originalCapacity",
      "storageKey": "originalCapacity(unit:\"AmpHour\")"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "8826476dd5b3906852a7ee7089cad8d0";

export default node;
