/**
 * @generated SignedSource<<cfb5621f2e18c2f1e4e44522a51e4060>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationType = "BatteryDischarge" | "GeneratorRun" | "ProductUpdate" | "ScheduledBatteryTestComplete" | "%future added value";
export type watchControlWatchStatusQuery$variables = {
  id: string;
};
export type watchControlWatchStatusQuery$data = {
  readonly currentUser: {
    readonly notificationWatch: {
      readonly enabledNotifications: ReadonlyArray<NotificationType>;
    } | null;
    readonly notificationWatchLimit: number;
    readonly notificationWatches: {
      readonly total: number;
    };
  };
};
export type watchControlWatchStatusQuery = {
  response: watchControlWatchStatusQuery$data;
  variables: watchControlWatchStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "entity",
      "variableName": "id"
    }
  ],
  "concreteType": "NotificationWatch",
  "kind": "LinkedField",
  "name": "notificationWatch",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledNotifications",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PaginatedNotificationWatch",
  "kind": "LinkedField",
  "name": "notificationWatches",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationWatchLimit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "watchControlWatchStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "watchControlWatchStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac4e8cb0aef16ccf2ef985f68e47749a",
    "id": null,
    "metadata": {},
    "name": "watchControlWatchStatusQuery",
    "operationKind": "query",
    "text": "query watchControlWatchStatusQuery(\n  $id: ID!\n) {\n  currentUser {\n    notificationWatch(entity: $id) {\n      enabledNotifications\n    }\n    notificationWatches {\n      total\n    }\n    notificationWatchLimit\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6e9d5007fbd3173a9115b1d017941d5";

export default node;
