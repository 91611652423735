/**
 * @generated SignedSource<<8b186a85b963e5197dfd39ea8a2a2b72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationPaneReloadQuery$variables = Record<PropertyKey, never>;
export type NotificationPaneReloadQuery$data = {
  readonly notifications: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationPane_data">;
  };
};
export type NotificationPaneReloadQuery = {
  response: NotificationPaneReloadQuery$data;
  variables: NotificationPaneReloadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationPaneReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedNotifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationPane_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationPaneReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedNotifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unread",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "context",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "GeneratorRunReport",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "BatteryTest",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BatteryTest",
                        "kind": "LinkedField",
                        "name": "task",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "DeviceBatteryTestResults",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "BatteryType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "Device",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "Site",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "926e4739bc61d5a6c3ecc70389e9b345",
    "id": null,
    "metadata": {},
    "name": "NotificationPaneReloadQuery",
    "operationKind": "query",
    "text": "query NotificationPaneReloadQuery {\n  notifications {\n    ...NotificationPane_data\n  }\n}\n\nfragment NotificationPane_data on PaginatedNotifications {\n  total\n  data {\n    id\n    type\n    unread\n    timestamp\n    message\n    link\n    context {\n      __typename\n      ... on GeneratorRunReport {\n        id\n      }\n      ... on BatteryTest {\n        id\n      }\n      ... on DeviceBatteryTestResults {\n        id\n        task {\n          id\n        }\n        device {\n          id\n        }\n      }\n      ... on BatteryType {\n        id\n      }\n      ... on Device {\n        id\n      }\n      ... on Site {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "645e695f07e5bb975e43d30db98907f1";

export default node;
