import { object, string } from 'yup';

export const DeliverySettingsValidationSchema = object({
    scheduledBatteryTestComplete: string().required('This is required'),
    generatorRun: string().required('This is required'),
    batteryDischarge: string().required('This is required'),
    productUpdate: string().required('This is required'),

    emailDelay: string().required('This is required'),
});
