/**
 * @generated SignedSource<<29f6e4a796ad14084ddedf3f272cb494>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTypeDistributionType = "Capacity" | "CellCapacityRating" | "CellReferenceTemperature" | "CellsPerBloc" | "PeukertsConstant" | "%future added value";
export type UnitApparentPower = "KiloVoltAmp" | "VoltAmp" | "%future added value";
export type UnitCharge = "AmpHour" | "MilliAmpHour" | "%future added value";
export type UnitCurrent = "Amp" | "Milliamp" | "%future added value";
export type UnitEnergy = "KiloWattHour" | "WattHour" | "%future added value";
export type UnitFlowRate = "LitrePerHour" | "USGallonPerHour" | "%future added value";
export type UnitPower = "KiloWatt" | "Watt" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitReactivePower = "KiloVoltAmpReactive" | "VoltAmpReactive" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitTime = "Hour" | "Minute" | "Second" | "%future added value";
export type UnitVoltage = "Millivolt" | "Volt" | "%future added value";
export type UnitVolume = "Litre" | "USGallon" | "%future added value";
export type UnitSettings = {
  apparentPower?: UnitApparentPower | null;
  charge?: UnitCharge | null;
  current?: UnitCurrent | null;
  energy?: UnitEnergy | null;
  power?: UnitPower | null;
  pressure?: UnitPressure | null;
  reactivePower?: UnitReactivePower | null;
  temperature?: UnitTemperature | null;
  time?: UnitTime | null;
  voltage?: UnitVoltage | null;
  volume?: UnitVolume | null;
  volumetricFlowRate?: UnitFlowRate | null;
};
export type BatteryTypeFilterRange_batteryTypeDistributionQuery$variables = {
  type: BatteryTypeDistributionType;
  units: UnitSettings;
};
export type BatteryTypeFilterRange_batteryTypeDistributionQuery$data = {
  readonly batteryTypeDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type BatteryTypeFilterRange_batteryTypeDistributionQuery = {
  response: BatteryTypeFilterRange_batteryTypeDistributionQuery$data;
  variables: BatteryTypeFilterRange_batteryTypeDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "units"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      },
      {
        "kind": "Variable",
        "name": "units",
        "variableName": "units"
      }
    ],
    "concreteType": "BatteryTypeDistribution",
    "kind": "LinkedField",
    "name": "batteryTypeDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BatteryTypeDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryTypeFilterRange_batteryTypeDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BatteryTypeFilterRange_batteryTypeDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98b5c2602e9d3c55b5135aa7b5a0af68",
    "id": null,
    "metadata": {},
    "name": "BatteryTypeFilterRange_batteryTypeDistributionQuery",
    "operationKind": "query",
    "text": "query BatteryTypeFilterRange_batteryTypeDistributionQuery(\n  $type: BatteryTypeDistributionType!\n  $units: UnitSettings!\n) {\n  batteryTypeDistribution(type: $type, units: $units) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ebb6bafe7af078c2d717226469fffb8";

export default node;
