/**
 * @generated SignedSource<<c3b6ac544ef6aa566bef013970dbab37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationType = "BatteryDischarge" | "GeneratorRun" | "ProductUpdate" | "ScheduledBatteryTestComplete" | "%future added value";
export type NotificationWatchSortField = "EntityName" | "UpdatedTimestamp" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type NotificationWatchOrdering = {
  direction: SortDirection;
  field: NotificationWatchSortField;
};
export type WatchlistTableRequestQuery$variables = {
  orderBy?: NotificationWatchOrdering | null;
  page?: number | null;
  pageSize?: number | null;
  search?: string | null;
};
export type WatchlistTableRequestQuery$data = {
  readonly currentUser: {
    readonly notificationWatchLimit: number;
    readonly notificationWatches: {
      readonly data: ReadonlyArray<{
        readonly enabledNotifications: ReadonlyArray<NotificationType>;
        readonly entity: {
          readonly __typename: "BatteryTest";
          readonly entityName?: string | null;
          readonly id: string;
        } | {
          readonly __typename: "Device";
          readonly id: string;
          readonly name: string;
        } | {
          readonly __typename: "Site";
          readonly address: {
            readonly state: string;
          };
          readonly id: string;
          readonly name: string;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
        readonly updatedAt: string | null;
      }>;
      readonly pageInfo: {
        readonly hasNext: boolean | null;
        readonly hasPrevious: boolean | null;
        readonly page: number;
        readonly total: number;
      };
      readonly total: number;
    };
    readonly overallWatches: {
      readonly total: number;
    };
  };
};
export type WatchlistTableRequestQuery = {
  response: WatchlistTableRequestQuery$data;
  variables: WatchlistTableRequestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v1 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": 50,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notificationWatchLimit",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy"
    },
    {
      "kind": "Variable",
      "name": "page",
      "variableName": "page"
    },
    {
      "kind": "Variable",
      "name": "pageSize",
      "variableName": "pageSize"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    }
  ],
  "concreteType": "PaginatedNotificationWatch",
  "kind": "LinkedField",
  "name": "notificationWatches",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationWatch",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "entity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "type": "Device",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Address",
                  "kind": "LinkedField",
                  "name": "address",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Site",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": "entityName",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "type": "Task",
                  "abstractKey": "__isTask"
                }
              ],
              "type": "BatteryTest",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabledNotifications",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNext",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPrevious",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": "overallWatches",
  "args": null,
  "concreteType": "PaginatedNotificationWatch",
  "kind": "LinkedField",
  "name": "notificationWatches",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchlistTableRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WatchlistTableRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f180b27e534daf2c3b1d85a258ff58a",
    "id": null,
    "metadata": {},
    "name": "WatchlistTableRequestQuery",
    "operationKind": "query",
    "text": "query WatchlistTableRequestQuery(\n  $page: Int = 1\n  $pageSize: Int = 50\n  $search: String\n  $orderBy: NotificationWatchOrdering\n) {\n  currentUser {\n    notificationWatchLimit\n    notificationWatches(page: $page, search: $search, pageSize: $pageSize, orderBy: $orderBy) {\n      total\n      data {\n        entity {\n          __typename\n          ... on Device {\n            id\n            name\n          }\n          ... on Site {\n            id\n            name\n            address {\n              state\n            }\n          }\n          ... on BatteryTest {\n            id\n            ... on Task {\n              __isTask: __typename\n              entityName: name\n            }\n          }\n        }\n        enabledNotifications\n        updatedAt\n      }\n      pageInfo {\n        page\n        total\n        hasNext\n        hasPrevious\n      }\n    }\n    overallWatches: notificationWatches {\n      total\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "500673c5d18f466d585b0182c4c46748";

export default node;
