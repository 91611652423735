import React, { FC, ReactNode, useCallback, useState } from 'react';

import { CrossIcon, EyeIcon, EyeOffIcon, IconButton, SquareIconButton } from '@accesstel/pcm-ui';

import { autoUpdate, useFloating } from '@floating-ui/react';

import { MultipleSelect } from '../MultipleSelect';
import { WatchEntity, WatchEntityNames } from './common';
import { NotificationTypeFriendlyNames, NotificationTypesPerEntity } from './entity-options';
import { useWatchNotifications } from './watch-control';

export interface WatchButtonProps {
    entityId: string;
    entityType: WatchEntity;
    small?: boolean;
}

export const WatchButton: FC<WatchButtonProps> = ({ entityId, entityType, small }) => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        isWatchingEntity,
        watchedNotifications,
        isWatchLimitReached,
        startWatching,
        stopWatching,
        updateWatchPreferences,
    } = useWatchNotifications(entityId, entityType);

    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-end',
        open: isOpen,
        onOpenChange(open) {
            if (!open) {
                setIsOpen(false);
            }
        },
        whileElementsMounted: autoUpdate,
    });

    let tooltip: string;
    let icon: ReactNode;

    if (isWatchingEntity) {
        tooltip = 'You are watching this ' + WatchEntityNames[entityType];
        icon = <EyeIcon />;
    } else {
        if (isWatchLimitReached) {
            tooltip = 'Watch limit reached. Unwatch something first';
        } else {
            tooltip = 'Watch this ' + WatchEntityNames[entityType];
        }
        icon = <EyeOffIcon />;
    }

    const handleButtonClick = useCallback(() => {
        if (!isOpen && !isWatchingEntity) {
            // When you open it, you start watching it. You can cancel from the menu itself
            startWatching();
        }

        setIsOpen(open => !open);
    }, [isOpen, isWatchingEntity, startWatching]);

    return (
        <div ref={refs.setReference}>
            <SquareIconButton
                icon={icon}
                tooltip={tooltip}
                active={isWatchingEntity}
                isOpen={isOpen}
                onClick={handleButtonClick}
                disabled={isWatchLimitReached && !isWatchingEntity}
                size={small ? 'medium' : 'default'}
            />
            {isOpen && (
                <div ref={refs.setFloating} style={floatingStyles} className='z-50'>
                    <MultipleSelect
                        onClear={() => {}}
                        options={NotificationTypesPerEntity[entityType]}
                        renderItem={item => NotificationTypeFriendlyNames[item]}
                        renderItemSimple={item => NotificationTypeFriendlyNames[item]}
                        active={watchedNotifications}
                        title='Update your watch preferences'
                        onClose={() => setIsOpen(false)}
                        clearButton={
                            <IconButton
                                size='small'
                                title='Stop watching'
                                icon={<CrossIcon />}
                                onClick={() => {
                                    stopWatching();
                                    setIsOpen(false);
                                }}
                            />
                        }
                        onSubmit={types => {
                            updateWatchPreferences(types);
                            setIsOpen(false);
                        }}
                        overwriteHideClearButton={() => false}
                    />
                </div>
            )}
        </div>
    );
};
