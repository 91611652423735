import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { FormArea, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Formik, FormikHelpers } from 'formik';
import { Duration } from 'luxon';

import { DeliverySettingsFormValues } from '../schema';
import { DeliverySettingsValidationSchema } from '../validation';
import { DeliverySettingsContent } from './DeliverySettingsContent';
import {
    DeliverySettingsFragment$data,
    DeliverySettingsFragment$key,
} from './__generated__/DeliverySettingsFragment.graphql';
import {
    DeliverySettingsUpdateMutation,
    UserNotificationDeliveryPreferencesIn,
    UserPreferenceIn,
} from './__generated__/DeliverySettingsUpdateMutation.graphql';

export interface DeliverySettingsProps {
    settings: DeliverySettingsFragment$key;
}

export const DeliverySettings: FC<DeliverySettingsProps> = ({ settings }) => {
    const { show } = useToast();
    const data = useFragment(DeliverySettingsFragment, settings);

    const [saveDeliverySettings] = useMutation<DeliverySettingsUpdateMutation>(UpdateMutation);
    const environment = useRelayEnvironment();

    const handleSubmit = useCallback(
        (values: DeliverySettingsFormValues, { setSubmitting }: FormikHelpers<DeliverySettingsFormValues>) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                setSubmitting(false);
                return;
            }

            setSubmitting(true);
            saveDeliverySettings({
                variables: {
                    input: submitData,
                },
                onCompleted: data => {
                    if (data.updateCurrentUserPreferences.success) {
                        show({
                            text: 'Saved delivery preferences',
                            variant: 'info',
                        });

                        // reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next: () => {
                                setSubmitting(false);
                            },
                            error: (error: unknown) => {
                                setSubmitting(false);
                                show({
                                    text: 'Unable to save changes. Please try again later',
                                    variant: 'error',
                                });
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Notification delivery settings preferences save');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes.',
                            variant: 'error',
                        });
                    }
                },
                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes. Please try again later',
                        variant: 'error',
                    });
                    captureException(error, scope => {
                        scope.setTag('Function', 'Notification delivery settings preferences save');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveDeliverySettings, show]
    );

    return (
        <FormArea
            label='Notification Visibility'
            blurb='Preferences on how you want to be notified for each notification type'
        >
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={DeliverySettingsValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <DeliverySettingsContent />
            </Formik>
        </FormArea>
    );
};

export const DeliverySettingsFragment = graphql`
    fragment DeliverySettingsFragment on UserPreferences {
        notifications {
            emailDelay
            delivery {
                generatorRun
                batteryDischarge
                scheduledBatteryTestComplete
                productUpdate
            }
        }
    }
`;

const UpdateMutation = graphql`
    mutation DeliverySettingsUpdateMutation($input: UserPreferenceIn!) {
        updateCurrentUserPreferences(preferences: $input) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query DeliverySettingsReloadQuery {
        currentUser {
            preferences {
                ...DeliverySettingsFragment
            }
        }
    }
`;

function toInitialValues(data: DeliverySettingsFragment$data): DeliverySettingsFormValues {
    return {
        productUpdate: data.notifications.delivery.productUpdate,
        batteryDischarge: data.notifications.delivery.batteryDischarge,
        scheduledBatteryTestComplete: data.notifications.delivery.scheduledBatteryTestComplete,
        generatorRun: data.notifications.delivery.generatorRun,

        emailDelay: Duration.fromISO(data.notifications.emailDelay),
    };
}

function toSubmitData(
    values: DeliverySettingsFormValues,
    initialValues: DeliverySettingsFormValues
): UserPreferenceIn | null {
    const notificationDeliverySubmitData: UserNotificationDeliveryPreferencesIn = {};
    let notificationEmailDelaySubmitData: Duration | undefined;

    let changed = false;

    if (values.productUpdate !== initialValues.productUpdate) {
        notificationDeliverySubmitData.productUpdate = values.productUpdate;
        changed = true;
    }

    if (values.generatorRun !== initialValues.generatorRun) {
        notificationDeliverySubmitData.generatorRun = values.generatorRun;
        changed = true;
    }

    if (values.batteryDischarge !== initialValues.batteryDischarge) {
        notificationDeliverySubmitData.batteryDischarge = values.batteryDischarge;
        changed = true;
    }

    if (values.scheduledBatteryTestComplete !== initialValues.scheduledBatteryTestComplete) {
        notificationDeliverySubmitData.scheduledBatteryTestComplete = values.scheduledBatteryTestComplete;
        changed = true;
    }

    if (values.emailDelay.toISO() !== initialValues.emailDelay.toISO()) {
        notificationEmailDelaySubmitData = values.emailDelay;
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return {
        notificationDelivery: notificationDeliverySubmitData,
        notificationEmailDelay: notificationEmailDelaySubmitData?.toISO(),
    };
}
