import { FC, ReactNode } from 'react';
import React from 'react';
import { Environment, commitMutation, useRelayEnvironment } from 'react-relay';

import { EyeOffIcon, SearchBox, Tooltip, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { createColumnHelper } from '@tanstack/react-table';
import graphql from 'babel-plugin-relay/macro';
import { NotificationWatchlistColumn } from 'filters/notification-watchlist';
import { ColumnWithId, SortDirection, TableActionType, useTableReducer } from 'layouts';
import { CoreTableLayout } from 'layouts/TableLayout/CoreTableLayout';
import { useConfirmationModal } from 'lib/confirmation';
import { useQuery } from 'lib/query-helpers';

import {
    NotificationWatchOrdering,
    NotificationWatchSortField,
    WatchlistTableRequestQuery,
} from './__generated__/WatchlistTableRequestQuery.graphql';
import { WatchlistTableUnwatchAllMutation } from './__generated__/WatchlistTableUnwatchAllMutation.graphql';
import { WatchlistTableUnwatchEntityMutation } from './__generated__/WatchlistTableUnwatchEntityMutation.graphql';
import { NotificationWatchlist, NotificationWatchlistBaseTableColumns } from './settings';

const columnHelper = createColumnHelper<NotificationWatchlist>();

export const WatchlistTable: FC = () => {
    const environment = useRelayEnvironment();

    const [showConfirmationModal, confirmationModal] = useConfirmationModal();
    const { show: showToast } = useToast();

    const UnwatchActionColumn = columnHelper.display({
        id: 'unwatch',
        header: '',
        exportHeader: '',
        cell: ({ row }) => {
            if (row.original.entity.__typename === '%other') {
                return '';
            }

            const onSuccess = () => {
                showToast({ text: 'Successfully unwatched' });
                retry();
            };
            const onFailure = () => showToast({ text: 'Error unwatching', variant: 'error' });

            return renderUnwatchAction(row.original.entity.id, environment, onSuccess, onFailure);
        },
        exportValue: () => '',
        meta: {
            filterable: false,
            sortable: false,
        },
    });

    const columnDefinitions = [...NotificationWatchlistBaseTableColumns, UnwatchActionColumn] as ColumnWithId<
        NotificationWatchlistColumn,
        NotificationWatchlist
    >[];
    const columnDefinitionIds = columnDefinitions.map(column => column.id);

    const [tableState, dispatchTableState] = useTableReducer<NotificationWatchlistColumn>({
        defaultSortColumn: NotificationWatchlistColumn.UpdatedTime,
        defaultSortDirection: SortDirection.Descending,
        allColumns: columnDefinitions,
        defaultVisibleColumns: columnDefinitionIds,
        storageKeyPrefix: 'watchlist-table',

        disableUrlSortSync: true,
    });

    const sortObject: NotificationWatchOrdering = {
        field: tableState.sortColumn as NotificationWatchSortField,
        direction: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const {
        data: props,
        error,
        retry,
        isFetching,
    } = useQuery<WatchlistTableRequestQuery>(
        WatchlistTableQuery,
        {
            search: tableState.search,
            page: tableState.page,
            pageSize: tableState.pageSize,
            orderBy: sortObject,
        },
        { fetchPolicy: 'network-only' }
    );

    if (!props) {
        return null;
    }

    const { currentUser: data } = props;
    const limitReached = data.overallWatches.total >= data.notificationWatchLimit;

    const getUnwatchAllButtonStyle = () => {
        if (data.notificationWatches.total === 0) {
            return 'text-eggplantExtraLight cursor-default';
        }

        return 'text-coralRegular cursor-pointer';
    };

    const openUnwatchAllConfirmation = () => {
        if (data.notificationWatches.total === 0) {
            return;
        }

        showConfirmationModal({
            title: 'Unwatch all entities?',
            content: 'Are you sure you want to unwatch all entities?',
            onResult: async buttonId => {
                if (buttonId === 'cancel') {
                    return;
                }

                try {
                    const result = await unwatchAllEntities(environment);
                    if (result) {
                        showToast({
                            text: 'Successfully unwatched all entities',
                        });

                        retry(); // Refresh the table
                    } else {
                        showToast({
                            text: 'Error unwatching all entities',
                            variant: 'error',
                        });
                    }
                } catch (error) {
                    captureException(error, scope => {
                        scope.setTag('Function', 'Unwatch All Entities');
                        return scope;
                    });
                    showToast({
                        text: 'Error unwatching all entities',
                        variant: 'error',
                    });
                }
            },
        });
    };

    return (
        <div className='flex flex-col p-4 bg-white gap-4'>
            <div>
                <div className='text-eggplantRegular'>Watched Items</div>
                <div className='flex justify-between items-center text-eggplantRegular text-sm font-light'>
                    <Tooltip content={limitReached ? 'Watch limit reached' : undefined}>
                        <span className={limitReached ? 'text-coralRegular' : ''}>
                            {data.notificationWatches.total} out of {data.notificationWatchLimit} watches
                        </span>
                    </Tooltip>
                    <span
                        role='button'
                        className={getUnwatchAllButtonStyle()}
                        onClick={() => openUnwatchAllConfirmation()}
                    >
                        Unwatch all
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='w-96'>
                    <SearchBox
                        id='watchlist-search'
                        placeHolder='Search'
                        as='div'
                        backgroundClass='bg-grayRegular'
                        className='bg-grayRegular'
                        value={tableState.search}
                        renderResult={() => ''}
                        onChange={search => dispatchTableState({ type: TableActionType.UpdateSearch, search })}
                    />
                </div>
                <CoreTableLayout
                    tableSize='compact'
                    data={data.notificationWatches.data}
                    columns={columnDefinitions}
                    tableState={tableState}
                    dispatchTableState={dispatchTableState}
                    tableVariant='white'
                    hasError={!!error}
                    onRetry={retry}
                    isProcessing={isFetching}
                    page={data.notificationWatches.pageInfo.page}
                    pageCount={data.notificationWatches.pageInfo.total}
                    emptyMessage={
                        data.overallWatches.total > 0 ? 'No matching result' : 'You are not watching anything'
                    }
                    getRowId={row => {
                        if (row.entity.__typename === '%other') {
                            return '';
                        }

                        return row.entity.id;
                    }}
                />
            </div>
            {confirmationModal}
        </div>
    );
};

const WatchlistTableQuery = graphql`
    query WatchlistTableRequestQuery(
        $page: Int = 1
        $pageSize: Int = 50
        $search: String
        $orderBy: NotificationWatchOrdering
    ) {
        currentUser {
            notificationWatchLimit
            notificationWatches(page: $page, search: $search, pageSize: $pageSize, orderBy: $orderBy) {
                total
                data {
                    entity {
                        __typename
                        ... on Device {
                            id
                            name
                        }
                        ... on Site {
                            id
                            name
                            address {
                                state
                            }
                        }
                        ... on BatteryTest {
                            id
                            ... on Task {
                                entityName: name
                            }
                        }
                    }
                    enabledNotifications
                    updatedAt
                }
                pageInfo {
                    page
                    total
                    hasNext
                    hasPrevious
                }
            }
            overallWatches: notificationWatches {
                total
            }
        }
    }
`;

function unwatchEntity(entityId: string, environment: Environment): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        commitMutation<WatchlistTableUnwatchEntityMutation>(environment, {
            mutation: graphql`
                mutation WatchlistTableUnwatchEntityMutation($entityId: ID!) {
                    unwatchForNotifications(entity: $entityId) {
                        success
                        problems
                    }
                }
            `,
            variables: {
                entityId,
            },
            onCompleted: (response, errors) => {
                if (errors) {
                    reject(errors);
                    return;
                }

                if (!response.unwatchForNotifications) {
                    reject();
                    return;
                }

                if (!response.unwatchForNotifications.success) {
                    if (response.unwatchForNotifications.problems) {
                        reject(response.unwatchForNotifications.problems);
                    } else {
                        reject();
                    }
                    return;
                }

                resolve(response.unwatchForNotifications.success);
            },
            onError: error => {
                reject(error);
            },
        });
    });
}

function unwatchAllEntities(environment: Environment): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        commitMutation<WatchlistTableUnwatchAllMutation>(environment, {
            mutation: graphql`
                mutation WatchlistTableUnwatchAllMutation {
                    unwatchAllEntities
                }
            `,
            variables: {},
            onCompleted: (response, errors) => {
                if (errors) {
                    reject(errors);
                    return;
                }

                if (!response.unwatchAllEntities) {
                    reject();
                    return;
                }

                resolve(response.unwatchAllEntities);
            },
            onError: error => {
                reject(error);
            },
        });
    });
}

function renderUnwatchAction(
    entityId: string,
    environment: Environment,
    onSuccess?: () => void,
    onFailure?: () => void
): ReactNode {
    return (
        <Tooltip content='Unwatch this entity'>
            <div
                className='w-4 h-4 text-eggplantRegular hover:text-coralRegular cursor-pointer'
                onClick={async () => {
                    try {
                        const result = await unwatchEntity(entityId, environment);
                        if (!result) {
                            onFailure?.();
                            return;
                        }

                        onSuccess?.();
                    } catch {
                        onFailure?.();
                    }
                }}
            >
                <EyeOffIcon />
            </div>
        </Tooltip>
    );
}
