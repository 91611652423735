import React, { FC } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { getGlobalEnvironment } from '../../../lib/environment';
import { NotificationSettingsContentQuery } from './__generated__/NotificationSettingsContentQuery.graphql';
import { DeliverySettings } from './sections/DeliverySettings';
import { WatchlistTable } from './sections/WatchlistTable';

export interface NotificationSettingsContentProps {
    queryRef: PreloadedQuery<NotificationSettingsContentQuery>;
}

export const NotificationSettingsContent: FC<NotificationSettingsContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(LoadQuery, queryRef);

    return (
        <div className='space-y-6 mb-32 text-eggplantRegular'>
            <PageHeading value='Notification Settings' />

            <div className='space-y-6'>
                <DeliverySettings settings={data.currentUser.preferences} />
            </div>
            <div className='space-y-6'>
                <WatchlistTable />
            </div>
        </div>
    );
};

export async function loadNotificationSettingsContent() {
    return loadQuery(getGlobalEnvironment(), LoadQuery, {});
}

const LoadQuery = graphql`
    query NotificationSettingsContentQuery {
        currentUser {
            preferences {
                ...DeliverySettingsFragment
            }
        }
    }
`;
