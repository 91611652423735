/**
 * @generated SignedSource<<3f9a768ca1ae487957e5b2173f412a2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliverySettingsReloadQuery$variables = Record<PropertyKey, never>;
export type DeliverySettingsReloadQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly " $fragmentSpreads": FragmentRefs<"DeliverySettingsFragment">;
    };
  };
};
export type DeliverySettingsReloadQuery = {
  response: DeliverySettingsReloadQuery$data;
  variables: DeliverySettingsReloadQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliverySettingsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeliverySettingsFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeliverySettingsReloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationsSettings",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailDelay",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNotificationDeliveryPreferences",
                    "kind": "LinkedField",
                    "name": "delivery",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "generatorRun",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "batteryDischarge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledBatteryTestComplete",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productUpdate",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc71a112dc048050b76f48d1e69a00d9",
    "id": null,
    "metadata": {},
    "name": "DeliverySettingsReloadQuery",
    "operationKind": "query",
    "text": "query DeliverySettingsReloadQuery {\n  currentUser {\n    preferences {\n      ...DeliverySettingsFragment\n    }\n    id\n  }\n}\n\nfragment DeliverySettingsFragment on UserPreferences {\n  notifications {\n    emailDelay\n    delivery {\n      generatorRun\n      batteryDischarge\n      scheduledBatteryTestComplete\n      productUpdate\n    }\n  }\n}\n"
  }
};

(node as any).hash = "da7b24d9be7e2107ac11c3b7e66bea9c";

export default node;
