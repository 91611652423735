/**
 * This file contains the logic for handling units in the personal settings page.
 *
 * Changes to the available units in the API must be reflected here.
 * Any differences should hopefully cause an error when compiling.
 * Any new units will not cause an error, so you must add them here.
 *
 * There are two main parts to this file:
 * 1. The types (with options) for the units, which are based on the API.
 * 2. The presets for the units.
 *
 * New units need to be added to the types and presets (if applicable).
 */
import React, { ReactNode } from 'react';

import {
    UnitFlowRate as BaseUnitFlowRate,
    UnitPressure as BaseUnitPressure,
    UnitTemperature as BaseUnitTemperature,
    UnitVolume as BaseUnitVolume,
} from '../sections/__generated__/UnitSettingsFragment.graphql';

export const UnknownOption = '%future added value' as const;

export function stripUnknown<T extends string | typeof UnknownOption>(
    string: T
): Exclude<T, typeof UnknownOption> | null {
    if (string === UnknownOption) {
        return null;
    }

    return string as Exclude<T, typeof UnknownOption>;
}

interface UnitLabel {
    label: string;
    unit: string;
}

export type UnitTemperature = Exclude<BaseUnitTemperature, typeof UnknownOption>;
export const UnitTemperatureOptions: Record<UnitTemperature, UnitLabel> = {
    Celsius: {
        label: 'Celsius',
        unit: '°C',
    },
    Fahrenheit: {
        label: 'Fahrenheit',
        unit: '°F',
    },
    Kelvin: {
        label: 'Kelvin',
        unit: 'K',
    },
};

export type UnitVolume = Exclude<BaseUnitVolume, typeof UnknownOption>;
export const UnitVolumeOptions: Record<UnitVolume, UnitLabel> = {
    Litre: {
        label: 'Litres',
        unit: 'L',
    },
    USGallon: {
        label: 'Gallons',
        unit: 'gal',
    },
};

export type UnitPressure = Exclude<BaseUnitPressure, typeof UnknownOption>;
export const UnitPressureOptions: Record<UnitPressure, UnitLabel> = {
    Bar: {
        label: 'Bar',
        unit: 'bar',
    },
    PSI: {
        label: 'psi',
        unit: 'psi',
    },
    KiloPascal: {
        label: 'Kilopascal',
        unit: 'kPa',
    },
    Pascal: {
        label: 'Pascal',
        unit: 'Pa',
    },
};

export type UnitVolumetricFlowRate = Exclude<BaseUnitFlowRate, typeof UnknownOption>;
export const UnitVolumetricFlowRateOptions: Record<UnitVolumetricFlowRate, UnitLabel> = {
    LitrePerHour: {
        label: 'Litres Per Hour',
        unit: 'L/h',
    },
    USGallonPerHour: {
        label: 'Gallons Per Hour',
        unit: 'gal/h',
    },
};

export function convertOptionsToSelectOptions(options: Record<string, UnitLabel>): string[] {
    return Object.keys(options);
}

export function renderOption(option: string | number, options: Record<string, UnitLabel>): ReactNode {
    return (
        <div>
            <span>{options[option].label} </span>
            <span>({options[option].unit})</span>
        </div>
    );
}

export enum UnitPreset {
    Metric = 'metric',
    Imperial = 'imperial',
    Custom = 'custom',
}

export const UnitPresetLabels: Record<UnitPreset, string> = {
    [UnitPreset.Metric]: 'Metric',
    [UnitPreset.Imperial]: 'Imperial',
    [UnitPreset.Custom]: 'Custom',
};

interface Preset {
    temperature: UnitTemperature;
    pressure: UnitPressure;
    volume: UnitVolume;
    volumetricFlowRate: UnitVolumetricFlowRate;
}

export const PresetOptions: Record<Exclude<UnitPreset, UnitPreset.Custom>, Preset> = {
    [UnitPreset.Metric]: {
        temperature: 'Celsius',
        pressure: 'KiloPascal',
        volume: 'Litre',
        volumetricFlowRate: 'LitrePerHour',
    },
    [UnitPreset.Imperial]: {
        temperature: 'Fahrenheit',
        pressure: 'PSI',
        volume: 'USGallon',
        volumetricFlowRate: 'USGallonPerHour',
    },
};
