import React, { FC, useCallback, useState } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import { Button, Domain, MenuItemType, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { TimeseriesOffsetSeries } from 'lib/dateChart';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { useZoomableData } from 'lib/line-chart-zoom';
import { useQuery } from 'lib/query-helpers';
import { formatBatteryTestName } from 'lib/textFormatters';
import { MetricsLineType } from 'lib/units';
import { DateTime, Duration } from 'luxon';

import { MetricLine } from '../../components/MetricsLine';
import { ChartTickCount } from '../../settings';
import { getDisplayInterval } from '../../test-result-view/common';
import { AnnotationType, DefaultAnnotationTypes, useAnnotations } from '../../test-result-view/lib/annotations';
import { useCDFOverlays } from '../../test-result-view/lib/cdf';
import { MenuOptions } from '../../test-result-view/lib/common';
import { MetricChartModalContentQuery } from './__generated__/MetricChartModalContentQuery.graphql';
import style from './style.module.css';

const DefaultChartXDomain: Domain<Duration> = ['dataMin', 'dataMax'];

interface MetricChartModalContentProps {
    currentTestId: string;
    otherTestIds: string[];
    metricType: MetricsLineType;
    duration: Duration;

    startTime: string | null;
    endTime: string | null;

    onClose: () => void;
}

export const MetricChartModalContent: FC<MetricChartModalContentProps> = ({
    currentTestId,
    otherTestIds,
    metricType,
    duration,
    onClose,

    startTime,
    endTime,
}) => {
    const environment = useRelayEnvironment();
    const interval = getDisplayInterval(Duration.fromMillis(0), duration, ChartTickCount);
    const unitPreferences = useCurrentUserUnitsPref();

    const data = useQuery<MetricChartModalContentQuery>(DataQuery, {
        testIds: [currentTestId, ...otherTestIds],
        begin: Duration.fromMillis(0).toISO(),
        end: duration.toISO(),
        interval: interval.toISO(),
        unitTemperature: unitPreferences.temperature,
    });

    const [selectedLegend, setSelectedLegend] = useState<string | null>(null);
    const [selectedMenuOption, setSelectedMenuOption] = useState<string | undefined>(MenuOptions.ViewAll);
    const [visibleAnnotations, setVisibleAnnotations] = useState<AnnotationType[]>(DefaultAnnotationTypes);

    const fetchData = useCallback(
        (interval: Duration | null, begin: Duration | null, end: Duration | null) => {
            if (!interval && startTime) {
                if (!endTime) {
                    interval = getDisplayInterval(DateTime.fromISO(startTime), DateTime.now(), ChartTickCount);
                } else {
                    interval = getDisplayInterval(
                        DateTime.fromISO(startTime),
                        DateTime.fromISO(endTime),
                        ChartTickCount
                    );
                }
            }

            return fetchQuery<MetricChartModalContentQuery>(environment, DataQuery, {
                testIds: [currentTestId, ...otherTestIds],
                begin: begin?.toISO(),
                end: end?.toISO(),
                interval: interval?.toISO(),
            }).toPromise();
        },
        [currentTestId, endTime, environment, otherTestIds, startTime]
    );

    const { chartDomain, doZoom, hasError, reloadData, zoomData } = useZoomableData(
        DefaultChartXDomain,
        (_, zoomed) => {
            if (!zoomed) {
                setSelectedMenuOption(MenuOptions.ViewAll);
                return;
            }

            setSelectedMenuOption(undefined);
        },
        fetchData,
        {
            averageTickCount: ChartTickCount,
        }
    );

    const latestData = zoomData ?? data.data;

    const relevantMetrics = latestData?.batteryTestResults.data?.map(test => {
        let threshold: number | null = null;
        if (test.task?.settings.threshold != null) {
            threshold = test.task.settings.threshold;
        } else if (test.device.battery.quickTestFailThreshold != null) {
            threshold = test.device.battery.quickTestFailThreshold;
        }

        let thresholdDuration: Duration | null = null;
        if (test.task?.settings?.reserveTime != null) {
            thresholdDuration = Duration.fromObject({ minutes: test.task.settings.reserveTime });
        } else if (test.device.battery.quickTestCheckPercent != null && test.device.battery.reserveTime != null) {
            thresholdDuration = Duration.fromObject({
                minutes: test.device.battery.reserveTime * (test.device.battery.quickTestCheckPercent / 100),
            });
        }

        const designReserveTime = test.device.battery.reserveTime;

        let data;
        if (metricType === 'temperature') {
            data = test.aggregatedTemperature;
        } else if (metricType === 'voltage') {
            data = test.aggregatedVoltage;
        } else if (metricType === 'current') {
            data = test.aggregatedCurrent;
        } else if (metricType === 'power') {
            data = test.aggregatedPower;
        }

        return {
            id: test.id,
            name: test.name,
            cause: test.cause,
            commencedTime: test.commencedTime,
            completedTime: test.completedTime,
            quickTestThresholdVoltage: threshold,
            quickTestThresholdTime: thresholdDuration,
            designReserveTime,
            minimumAllowedVoltage: test.device.battery.allowedVoltage?.minimum,
            maximumAllowedVoltage: test.device.battery.allowedVoltage?.maximum,
            markers: test.markers,
            coupDeFouet: test.coupDeFouet,
            data,
        };
    });

    const selectedTestMetrics = relevantMetrics?.find(test => test.id === currentTestId);
    const otherTestMetrics =
        relevantMetrics
            ?.filter(test => test.id !== currentTestId)
            .sort((testA, testB) => {
                // order by commenced time
                if (testA.commencedTime && testB.commencedTime) {
                    return testA.commencedTime.localeCompare(testB.commencedTime);
                }

                return 1;
            }) ?? [];

    const zoomMenuOptions: MenuItemType[] = [
        {
            id: MenuOptions.ViewAll,
            name: 'All',
            onClick() {
                doZoom(null);
                setSelectedMenuOption(MenuOptions.ViewAll);
            },
        },
    ];

    const [voltageMarkers, allChartMarkers] = useAnnotations(
        {
            startTime: selectedTestMetrics?.commencedTime ? DateTime.fromISO(selectedTestMetrics.commencedTime) : null,
            endTime: selectedTestMetrics?.completedTime ? DateTime.fromISO(selectedTestMetrics.completedTime) : null,
            quickTestThresholdVoltage: selectedTestMetrics?.quickTestThresholdVoltage ?? null,
            quickTestThresholdTime: selectedTestMetrics?.quickTestThresholdTime ?? null,
            designReserveTime: selectedTestMetrics?.designReserveTime ?? null,
            minimumAllowedVoltage: selectedTestMetrics?.minimumAllowedVoltage ?? null,
            maximumAllowedVoltage: selectedTestMetrics?.maximumAllowedVoltage ?? null,
        },
        selectedTestMetrics?.markers ?? []
    );

    const [cdfMarkers, cdfMenuOptions] = useCDFOverlays(
        selectedTestMetrics?.commencedTime ? DateTime.fromISO(selectedTestMetrics.commencedTime) : DateTime.local(),
        selectedTestMetrics?.completedTime ? DateTime.fromISO(selectedTestMetrics.completedTime) : DateTime.local(),
        chartDomain,
        selectedTestMetrics?.coupDeFouet ?? null,
        cdfDomain => {
            doZoom(cdfDomain);
            setSelectedMenuOption(MenuOptions.ViewCDF);
        }
    );

    const markers = [...voltageMarkers];
    if (metricType === 'voltage') {
        zoomMenuOptions.push(...cdfMenuOptions);
        markers.push(...cdfMarkers);
    }

    const uniqueAnnotationTypes = new Set<AnnotationType>();
    for (const marker of markers) {
        uniqueAnnotationTypes.add(marker.annotationType);
    }
    for (const marker of allChartMarkers) {
        uniqueAnnotationTypes.add(marker.annotationType);
    }
    const uniqueAnnotationTypesAsArray = Array.from(uniqueAnnotationTypes);

    const getPoints = (testId: string) => {
        const test = relevantMetrics?.find(test => test.id === testId); // FIXME: This is a bit expensive, to refactor
        const startTime = test?.commencedTime ?? '';

        return test?.data?.flatMap(dataPoint => {
            if (dataPoint.average) {
                const timestamp = DateTime.fromISO(dataPoint.timestamp);
                const timestampDiff = timestamp.diff(DateTime.fromISO(startTime));

                const value =
                    metricType === 'power' || metricType === 'current' ? -dataPoint.average : dataPoint.average;

                return {
                    timestamp,
                    offset: timestampDiff,
                    value,
                };
            } else {
                return [];
            }
        });
    };

    const handleLegendClick = (id?: string) => {
        if (id === selectedLegend) {
            setSelectedLegend(null);
        } else if (id) {
            setSelectedLegend(id);
        }
    };

    const chartSeries: TimeseriesOffsetSeries[] = [
        {
            id: currentTestId,
            name: 'Selected Test', // TODO: Discussions around showing the test name/date
            color: Theme.coralRegular,
            points: getPoints(currentTestId) ?? [],
            dots:
                currentTestId === selectedLegend
                    ? { stroke: Theme.coralRegular, strokeWidth: 1, fill: Theme.coralRegular }
                    : false,
        },
        ...otherTestMetrics.map(test => {
            const isHightlighedTest = test.id === selectedLegend;

            return {
                id: test.id,
                name:
                    test.name ?? test.commencedTime
                        ? getDateTimeFormat(test.commencedTime!)
                        : formatBatteryTestName(test.cause), // TODO: Discussions around showing the test name/date
                color: isHightlighedTest ? Theme.pineRegular : Theme.eggplantRegular,
                points: getPoints(test.id) ?? [],
                dots: isHightlighedTest
                    ? { stroke: Theme.pineRegular, strokeWidth: 1, fill: Theme.pineRegular }
                    : false,
            };
        }),
    ];

    const voltageChartMarkers = [...markers, ...allChartMarkers].filter(marker =>
        visibleAnnotations.includes(marker.annotationType)
    );

    return (
        <div className={classNames('space-y-4', style.chart_modal_container)}>
            <MetricLine
                showLegend
                type={metricType}
                series={chartSeries}
                xDomain={chartDomain}
                error={hasError}
                onRetry={reloadData}
                aspectRatio={3}
                legendOnClick={(_, id) => handleLegendClick(id)}
                height='h-80'
                allAnnotations={uniqueAnnotationTypesAsArray}
                visibleAnnotations={visibleAnnotations}
                onSetVisibleAnnotations={setVisibleAnnotations}
                markers={voltageChartMarkers}
                menuItems={zoomMenuOptions}
                menuPlaceholder='Zoom'
                menuSelected={selectedMenuOption}
                onZoom={doZoom}
            />
            <div className='flex flex-row justify-center'>
                <Button onClick={onClose} buttonText='Close' />
            </div>
        </div>
    );
};

const DataQuery = graphql`
    query MetricChartModalContentQuery(
        $testIds: [String!]!
        $begin: Duration
        $end: Duration
        $interval: Duration
        $unitTemperature: UnitTemperature
    ) {
        batteryTestResults(ids: $testIds) {
            data {
                id
                name
                cause
                commencedTime
                completedTime
                aggregatedTemperature(begin: $begin, end: $end, interval: $interval, unit: $unitTemperature) {
                    timestamp
                    average
                }
                aggregatedVoltage(begin: $begin, end: $end, interval: $interval) {
                    timestamp
                    average
                }
                aggregatedCurrent(begin: $begin, end: $end, interval: $interval) {
                    timestamp
                    average
                }
                aggregatedPower(begin: $begin, end: $end, interval: $interval) {
                    timestamp
                    average
                }
                coupDeFouet {
                    float
                    troughVoltage
                    troughOffset
                    plateauVoltage
                    plateauOffset
                    dip
                }
                markers {
                    timestamp
                    offset
                    type
                    source
                    description
                }
                task {
                    settings {
                        ... on BatteryTestTypeQuick {
                            reserveTime
                            threshold
                        }
                    }
                }
                device {
                    battery {
                        quickTestFailThreshold
                        quickTestCheckPercent
                        reserveTime(unit: Minute)
                        allowedVoltage {
                            minimum
                            maximum
                        }
                    }
                }
            }
        }
    }
`;
