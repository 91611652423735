/**
 * @generated SignedSource<<760137fe9a6721d8504da91b308e0a96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationType = "BatteryDischarge" | "GeneratorRun" | "ProductUpdate" | "ScheduledBatteryTestComplete" | "%future added value";
export type NotificationNewSubscription$variables = Record<PropertyKey, never>;
export type NotificationNewSubscription$data = {
  readonly onNotification: {
    readonly id: string;
    readonly type: NotificationType;
  } | null;
};
export type NotificationNewSubscription = {
  response: NotificationNewSubscription$data;
  variables: NotificationNewSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "onNotification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationNewSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationNewSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a420f7625824f47df404a059f0b7da57",
    "id": null,
    "metadata": {},
    "name": "NotificationNewSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationNewSubscription {\n  onNotification {\n    id\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "74e7766a39ba60704cd1a921aaae6bdf";

export default node;
