/**
 * @generated SignedSource<<73d270dd65a71c0d3d92915b9b5863b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationPaneMarkAllAsReadMutation$variables = Record<PropertyKey, never>;
export type NotificationPaneMarkAllAsReadMutation$data = {
  readonly markAllNotificationsAsRead: boolean | null;
};
export type NotificationPaneMarkAllAsReadMutation = {
  response: NotificationPaneMarkAllAsReadMutation$data;
  variables: NotificationPaneMarkAllAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "markAllNotificationsAsRead",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationPaneMarkAllAsReadMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationPaneMarkAllAsReadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b887cf555d3bc0bad9e6331cdd1428ff",
    "id": null,
    "metadata": {},
    "name": "NotificationPaneMarkAllAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationPaneMarkAllAsReadMutation {\n  markAllNotificationsAsRead\n}\n"
  }
};
})();

(node as any).hash = "4c10ca2b0f269f9fb83519b1fe3d0197";

export default node;
