import React, { Dispatch, ReactNode, useCallback, useMemo } from 'react';

import {
    ActiveFilterActionButton,
    ArrowDownIcon,
    ClickBehaviour,
    ColumnIcon,
    CommonTableProps,
    FooterAction,
    RenderRowExpansion,
    SimpleTableProps,
    SizeType,
    SortState,
    Table,
    TableWithChildrenProps,
    VariantType,
    useToast,
} from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import { SelectColumnPane } from 'components';
import { FilterBar } from 'components/FilterBar';
import { FilterAction, FilterActionType, FilterState, renderEmbeddedColumnFilter } from 'filters/common';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { DateTime } from 'luxon';

import { ExportTablePane } from '../../components/ExportTablePane';
import { TableStateForVariables } from '../../lib/tables/table-query';
import { ColumnWithId } from './TableLayout';
import { SelectionFooter } from './components/SelectionFooter';
import { exportDataToCsv } from './export';
import { TableAction, TableActionType } from './reducer';
import { SortDirection, TableState } from './state';

export interface CoreTableLayoutProps<
    ColumnType extends string,
    DataType,
    TypeMap extends Record<ColumnType, unknown>,
    ChildDataType,
> {
    data: readonly DataType[] | null;
    /**
     * The columns to display within this table. If you want to use the column reordering / editing feature,
     * you must set this to contain all possible columns. Which columns are visible will depend on the table
     * state.
     * If column reordering and editing is not enabled, this property will control which columns are visible
     * and in what order.
     */
    columns: ColumnWithId<ColumnType, DataType | ChildDataType>[];
    getRowId: (row: DataType | ChildDataType) => string;
    getSubRows?: (row: DataType) => ChildDataType[];
    isProcessing?: boolean;
    onRetry?: () => void;
    hasError?: boolean;
    page?: number;
    pageCount?: number;
    emptyMessage?: string;
    selection?: boolean;
    /**
     * If this is set, the handler is responsible for updating the table state
     */
    tableOnSelectRowCallback?: (id: string[]) => void;
    onRequestAllIds?: () => Promise<string[]> | string[];
    tableOnUnselectAllCallback?: () => void;
    getItemLink?: (item: DataType | ChildDataType) => string;
    rowExpansionComponent?: ReactNode | RenderRowExpansion<DataType | ChildDataType>;
    subRowExpansionComponent?: ReactNode | RenderRowExpansion<ChildDataType>;
    emptySubRowsMessage?: React.ReactNode;
    filterState?: FilterState<ColumnType, TypeMap>;
    dispatchFilterState?: Dispatch<FilterAction<TypeMap, ColumnType>>;
    tableState: TableState<ColumnType>;
    dispatchTableState: Dispatch<TableAction<ColumnType>>;
    additionalActions?: ActiveFilterActionButton[];
    allowEditingColumns?: boolean;
    clickBehaviour?: ClickBehaviour;
    tableVariant?: VariantType;
    tableSize?: SizeType;

    selectionFooterActions?: FooterAction[];
    selectionFooterSelectedItems?: string[];
    selectionFooterUnitOverride?: string;
    selectionFooterUnitPluralOverride?: string;
    allowFooterZeroCount?: boolean;
    unit?: string;
    unitPlural?: string;

    /**
     * When set, the export option is shown
     */
    exportEnabled?: boolean;
    /**
     * The base filename used by default when exporting.
     * The current date and time will be appended to this.
     */
    exportFilename?: string;
    /**
     * When exports are enabled, you should also provide a function which will fetch the data for the export.
     * @param request The request. Suitable for going into the fetchTable method on useTableQuery
     * @returns The data to export
     */
    exportFetchData?: (request: TableStateForVariables<ColumnType>) => Promise<readonly DataType[]>;
}

export function CoreTableLayout<
    ColumnType extends string,
    DataType,
    ChildDataType,
    TypeMap extends Record<ColumnType, unknown>,
>({
    data,
    columns,
    page = 1,
    pageCount = 1,
    emptyMessage = 'There are no results',
    isProcessing,
    hasError,
    filterState,
    dispatchFilterState,
    tableState,
    dispatchTableState,
    additionalActions,
    getRowId,
    getSubRows,
    rowExpansionComponent,
    onRetry,
    subRowExpansionComponent,
    emptySubRowsMessage,
    getItemLink,
    selection,
    tableOnSelectRowCallback,
    tableOnUnselectAllCallback,
    onRequestAllIds,
    clickBehaviour,
    tableVariant = 'default',
    tableSize = 'default',
    allowEditingColumns,

    selectionFooterActions = [],
    selectionFooterSelectedItems = tableState.selectedItems,
    selectionFooterUnitOverride,
    selectionFooterUnitPluralOverride,
    allowFooterZeroCount = false,
    unit = 'Item',
    unitPlural = `${unit}s`,
    exportFilename = 'export',
    exportEnabled,
    exportFetchData,
}: CoreTableLayoutProps<ColumnType, DataType, TypeMap, ChildDataType>) {
    const { show } = useToast();
    const unitPreferences = useCurrentUserUnitsPref();

    const currentlySortedColumn = useMemo<[string, SortState]>(() => {
        return [tableState.sortColumn, tableState.sortDirection as SortState];
    }, [tableState.sortColumn, tableState.sortDirection]);

    const filterMap = useMemo(() => {
        const outputs = {} as Record<ColumnType, boolean>;
        for (const column of columns) {
            const columnId = column.id;
            const value = filterState?.columnValues[columnId];
            if (Array.isArray(value)) {
                outputs[columnId] = value.length > 0;
            } else {
                outputs[columnId] = !!value;
            }
        }

        return outputs;
    }, [columns, filterState?.columnValues]);

    const handleSortClick = useCallback(
        (id: string, currentSortStatus: SortState) => {
            let sortDirection: SortDirection;
            switch (currentSortStatus) {
                case 'ascending':
                    sortDirection = SortDirection.Descending;
                    break;
                case 'unsorted':
                case 'descending':
                    sortDirection = SortDirection.Ascending;
                    break;
            }
            dispatchTableState({
                type: TableActionType.SortColumn,
                column: id as ColumnType,
                direction: sortDirection,
            });
        },
        [dispatchTableState]
    );

    const handleFilterClick = useCallback(
        (id: string) => {
            if (filterState?.activeColumn === id) {
                dispatchFilterState?.({ type: FilterActionType.HideAll });
            } else {
                dispatchFilterState?.({ type: FilterActionType.Show, column: id as ColumnType });
            }
        },
        [dispatchFilterState, filterState?.activeColumn]
    );

    const handleSetPage = useCallback(
        (page: number) => dispatchTableState({ type: TableActionType.ChangePage, page }),
        [dispatchTableState]
    );
    const handleSetSelectedRows = (selectedItems: string[]) => {
        if (tableOnSelectRowCallback) {
            tableOnSelectRowCallback(selectedItems);
        } else {
            dispatchTableState({ type: TableActionType.SetSelection, selection: selectedItems });
        }
    };

    const handleSelectAllClick = useCallback(() => {
        if (!onRequestAllIds) {
            return;
        }

        const isPromise = onRequestAllIds() instanceof Promise;

        if (isPromise) {
            (onRequestAllIds as () => Promise<string[]>)()
                .then(ids => {
                    dispatchTableState({ type: TableActionType.SetAllSelectableItems, items: ids });
                    dispatchTableState({ type: TableActionType.AppendSelection, selection: ids });
                })
                .catch(() => {
                    show({ text: 'Error selecting all rows', variant: 'error' });
                });
        } else {
            const ids = (onRequestAllIds as () => string[])();
            dispatchTableState({ type: TableActionType.SetAllSelectableItems, items: ids });
            dispatchTableState({ type: TableActionType.AppendSelection, selection: ids });
        }
    }, [dispatchTableState, onRequestAllIds, show]);

    const handleSelectNoneClick = useCallback(() => {
        if (!tableOnUnselectAllCallback) {
            return;
        }

        tableOnUnselectAllCallback();
        dispatchTableState({ type: TableActionType.SetSelection, selection: [] });
    }, [dispatchTableState, tableOnUnselectAllCallback]);

    const tableProps: CommonTableProps<DataType | ChildDataType> = {
        columns: allowEditingColumns
            ? columns.filter(column => tableState.visibleColumnsInOrder.includes(column.id))
            : columns,
        data,
        isProcessing,
        page,
        pageCount,
        onSetPage: handleSetPage,
        isError: hasError,
        onRetry,
        selectedItems: tableState.selectedItems,
        allItemsSelected:
            tableState.allSelectableItems != null &&
            tableState.selectedItems.length >= tableState.allSelectableItems.length,
        onSelectAll: handleSelectAllClick,
        onSelectNone: handleSelectNoneClick,
        onSelectedItemsChange: handleSetSelectedRows,
        emptyMessage,
        sortingEnabled: true,
        activeSortedColumn: currentlySortedColumn,
        onSortClick: handleSortClick,
        filteringEnabled: true,
        filteredColumns: filterMap,
        onFilterClick: handleFilterClick,
        filterComponentToShow:
            filterState && dispatchFilterState
                ? renderEmbeddedColumnFilter(filterState, dispatchFilterState)
                : undefined,
        onHorizontalScroll: () => dispatchFilterState?.({ type: FilterActionType.HideAll }),
        columnReorderEnabled: allowEditingColumns,
        columnOrder: tableState.visibleColumnsInOrder,
        onColumnReorder: columns =>
            dispatchTableState({ type: TableActionType.UpdateColumns, columns: columns as ColumnType[] }),
        variant: tableVariant,
        size: tableSize,
    };

    if (getSubRows) {
        const childTableProps = tableProps as TableWithChildrenProps<DataType, ChildDataType>;
        childTableProps.getSubRows = getSubRows;

        childTableProps.selectionMode = selection ? 'subrow' : 'none';
        childTableProps.emptySubRowsMessage = emptySubRowsMessage;

        if (getItemLink) {
            childTableProps.getSubRowLink = getItemLink;
            childTableProps.clickBehaviour = 'navigate';
        }
    } else {
        const normalTableProps = tableProps as SimpleTableProps<DataType>;
        normalTableProps.clickBehaviour = clickBehaviour;

        normalTableProps.selectionMode = selection ? 'row' : 'none';
        if (getItemLink) {
            normalTableProps.getRowLink = getItemLink;
            normalTableProps.clickBehaviour = 'navigate';
        }
    }

    if (rowExpansionComponent) {
        const normalTableProps = tableProps as SimpleTableProps<DataType>;
        normalTableProps.clickBehaviour = 'expand';
    }

    if (subRowExpansionComponent) {
        const childTableProps = tableProps as TableWithChildrenProps<DataType, ChildDataType>;
        childTableProps.clickBehaviour = 'expand';
        childTableProps.subRowExpansionComponent = subRowExpansionComponent;
        childTableProps.emptySubRowsMessage = emptySubRowsMessage;
    }

    const secondaryActionsForTable = [...(additionalActions ?? [])];
    if (allowEditingColumns) {
        secondaryActionsForTable.unshift({
            buttonIcon: <ColumnIcon />,
            buttonText: 'Edit Columns',
            onClick: () => dispatchTableState({ type: TableActionType.SetColumnEditPaneOpen, isOpen: true }),
            embeddedComponent: tableState.isColumnEditPaneOpen && (
                <SelectColumnPane
                    availableColumns={columns}
                    visibleColumns={tableState.visibleColumnsInOrder}
                    setVisibleColumns={columns => {
                        dispatchTableState({ type: TableActionType.UpdateColumns, columns });
                    }}
                    onClose={() => dispatchTableState({ type: TableActionType.SetColumnEditPaneOpen, isOpen: false })}
                    onReset={() => {
                        dispatchTableState({ type: TableActionType.ResetColumns });
                    }}
                    hideReset={tableState.areProvidedColumnsInDefaultOrder}
                />
            ),
        });
    }

    if (exportEnabled) {
        secondaryActionsForTable.push({
            buttonIcon: <ArrowDownIcon />,
            buttonText: 'Export',
            onClick: () => dispatchTableState({ type: TableActionType.SetExportPaneOpen, isOpen: true }),
            embeddedComponent: tableState.isExportPaneOpen && (
                <ExportTablePane
                    defaultFilename={`${exportFilename}-${DateTime.local().toFormat('yyyy-LL-dd-hh-mm-ss')}.csv`}
                    pages={pageCount}
                    currentPage={page}
                    onExport={(filename, pageStart, pageEnd, allColumns) => {
                        let columnsToExport: ColumnWithId<ColumnType, DataType | ChildDataType>[];
                        if (allColumns) {
                            columnsToExport = columns;
                        } else {
                            columnsToExport = tableState.visibleColumnsInOrder.map(columnId => {
                                const column = columns.find(column => column.id === columnId);
                                if (!column) {
                                    throw new Error(`Could not find column with id ${columnId}`);
                                }

                                return column;
                            });
                        }

                        if (exportFetchData) {
                            const pages = pageEnd - pageStart + 1;
                            const visibleColumns = columnsToExport.map(column => column.id);
                            const exportPageDataParams = {
                                page: pageStart,
                                pageSize: 50,
                                pageCount: pages,
                                orderBy: tableState.sortColumn,
                                orderDirection: tableState.sortDirection,
                                search: tableState.search,
                                visibleColumns,
                            };
                            const exporter = exportFetchData(exportPageDataParams);

                            show({
                                text: 'Exporting table',
                                variant: 'info',
                                displayUntil: exporter,
                            });

                            exporter
                                .then(data => {
                                    exportDataToCsv(filename, data, columnsToExport);
                                })
                                .catch(err => {
                                    show({ text: 'Error exporting data', variant: 'error' });
                                    captureException(err, scope => {
                                        scope.setTag('Function', 'exportFetchData');
                                        scope.setExtra('Params', exportPageDataParams);
                                        return scope;
                                    });
                                });
                        } else {
                            exportDataToCsv(filename, data ?? [], columnsToExport);
                        }
                    }}
                    onClose={() => dispatchTableState({ type: TableActionType.SetExportPaneOpen, isOpen: false })}
                />
            ),
        });
    }

    return (
        <div>
            {filterState && dispatchFilterState && (
                <FilterBar
                    state={filterState}
                    updateState={dispatchFilterState}
                    additionalActions={secondaryActionsForTable}
                    variant={tableVariant === 'white' ? 'dark' : 'light'}
                />
            )}
            <Table
                {...tableProps}
                getRowId={getRowId}
                rowExpansionComponent={rowExpansionComponent}
                meta={{ units: unitPreferences }}
            />
            {selection && selectionFooterActions.length > 0 && (
                <SelectionFooter
                    selectedCount={selectionFooterSelectedItems.length}
                    unitSingular={selectionFooterUnitOverride ?? unit}
                    unitPlural={selectionFooterUnitPluralOverride ?? unitPlural}
                    actions={selectionFooterActions}
                    allowShowOnZeroCount={allowFooterZeroCount}
                />
            )}
        </div>
    );
}
