/**
 * @generated SignedSource<<df9bf7c60be9473bb4eed798394076c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type UnitFlowRate = "LitrePerHour" | "USGallonPerHour" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitVolume = "Litre" | "USGallon" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UnitSettingsFragment$data = {
  readonly units: {
    readonly pressure: UnitPressure;
    readonly temperature: UnitTemperature;
    readonly volume: UnitVolume;
    readonly volumetricFlowRate: UnitFlowRate;
  };
  readonly " $fragmentType": "UnitSettingsFragment";
};
export type UnitSettingsFragment$key = {
  readonly " $data"?: UnitSettingsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnitSettingsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnitSettingsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserUnitPreferences",
      "kind": "LinkedField",
      "name": "units",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "temperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pressure",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volume",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volumetricFlowRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserPreferences",
  "abstractKey": null
};

(node as any).hash = "d41699228006f16285f64d41cbe88968";

export default node;
