import { NotificationType, WatchEntity } from './common';

export const NotificationTypesPerEntity: Record<WatchEntity, NotificationType[]> = {
    site: ['BatteryDischarge', 'GeneratorRun'],
    device: ['BatteryDischarge', 'GeneratorRun'],
    'battery-test': ['ScheduledBatteryTestComplete'],
};

export const NotificationTypeFriendlyNames: Record<NotificationType, string> = {
    ScheduledBatteryTestComplete: 'Scheduled Test Complete',
    GeneratorRun: 'Generator Run',
    BatteryDischarge: 'Battery Discharge',
    ProductUpdate: 'Product Update',
    '%future added value': 'Unknown',
};
