/**
 * @generated SignedSource<<5c108078dd28abcd20a6ac8d9293f3d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACDeviceSummary_acPower$data = {
  readonly outages: {
    readonly total: number;
  };
  readonly overvolts: {
    readonly total: number;
  };
  readonly reliability: {
    readonly durationSum: number;
  };
  readonly threshold: {
    readonly outage: number;
    readonly overVoltage: number;
    readonly underVoltage: number;
  };
  readonly undervolts: {
    readonly total: number;
  };
  readonly " $fragmentType": "ACDeviceSummary_acPower";
};
export type ACDeviceSummary_acPower$key = {
  readonly " $data"?: ACDeviceSummary_acPower$data;
  readonly " $fragmentSpreads": FragmentRefs<"ACDeviceSummary_acPower">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "startTime",
  "variableName": "startRange"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startRange"
    },
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ACDeviceSummary_acPower",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerThreshold",
      "kind": "LinkedField",
      "name": "threshold",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "underVoltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overVoltage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "timeRange",
          "variableName": "timeRange"
        }
      ],
      "concreteType": "DeviceAcPowerReliabilityMetrics",
      "kind": "LinkedField",
      "name": "reliability",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Second"
            },
            {
              "kind": "Literal",
              "name": "worstStatus",
              "value": "Outage"
            }
          ],
          "kind": "ScalarField",
          "name": "durationSum",
          "storageKey": "durationSum(unit:\"Second\",worstStatus:\"Outage\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "outages",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "worstStatus",
              "value": {
                "value": "Outage"
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "undervolts",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "worstStatus",
              "value": {
                "value": "UnderVoltage"
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "overvolts",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "worstStatus",
              "value": {
                "value": "OverVoltage"
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DeviceACPower",
  "abstractKey": null
};
})();

(node as any).hash = "5e8cc14f77d343a77c9e7e0b26bfc74e";

export default node;
