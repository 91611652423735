/**
 * @generated SignedSource<<8ae5698e760e6bfca0e4f470cd666406>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NotificationType = "BatteryDischarge" | "GeneratorRun" | "ProductUpdate" | "ScheduledBatteryTestComplete" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationPane_data$data = {
  readonly data: ReadonlyArray<{
    readonly context: {
      readonly __typename: "BatteryTest";
      readonly id: string;
    } | {
      readonly __typename: "DeviceBatteryTestResults";
      readonly device: {
        readonly id: string;
      };
      readonly id: string;
      readonly task: {
        readonly id: string;
      } | null;
    } | {
      readonly __typename: "GeneratorRunReport";
      readonly id: string;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
    readonly id: string;
    readonly link: string | null;
    readonly message: string;
    readonly timestamp: string;
    readonly type: NotificationType;
    readonly unread: boolean;
  }>;
  readonly total: number;
  readonly " $fragmentType": "NotificationPane_data";
};
export type NotificationPane_data$key = {
  readonly " $data"?: NotificationPane_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationPane_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationPane_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Notification",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unread",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "context",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "GeneratorRunReport",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "BatteryTest",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BatteryTest",
                  "kind": "LinkedField",
                  "name": "task",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Device",
                  "kind": "LinkedField",
                  "name": "device",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "DeviceBatteryTestResults",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PaginatedNotifications",
  "abstractKey": null
};
})();

(node as any).hash = "ee10d4f665e44476655204440bec076f";

export default node;
