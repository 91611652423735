/**
 * @generated SignedSource<<9356059919597a70c8cd7954db375ff2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NotificationType = "BatteryDischarge" | "GeneratorRun" | "ProductUpdate" | "ScheduledBatteryTestComplete" | "%future added value";
export type NotificationWatchErrorType = "InvalidEntityId" | "InvalidNotificationType" | "TooManyWatches" | "%future added value";
export type watchControlStartWatchingMutation$variables = {
  id: string;
  notificationTypes?: ReadonlyArray<NotificationType> | null;
};
export type watchControlStartWatchingMutation$data = {
  readonly watchForNotifications: {
    readonly problems: ReadonlyArray<NotificationWatchErrorType> | null;
    readonly success: boolean;
    readonly watch: {
      readonly enabledNotifications: ReadonlyArray<NotificationType>;
    } | null;
  };
};
export type watchControlStartWatchingMutation = {
  response: watchControlStartWatchingMutation$data;
  variables: watchControlStartWatchingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notificationTypes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entity",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "notifications",
        "variableName": "notificationTypes"
      }
    ],
    "concreteType": "NotificationWatchResult",
    "kind": "LinkedField",
    "name": "watchForNotifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationWatch",
        "kind": "LinkedField",
        "name": "watch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabledNotifications",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "watchControlStartWatchingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "watchControlStartWatchingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8c91fbcf6f262689325b8ec55b5fa303",
    "id": null,
    "metadata": {},
    "name": "watchControlStartWatchingMutation",
    "operationKind": "mutation",
    "text": "mutation watchControlStartWatchingMutation(\n  $id: ID!\n  $notificationTypes: [NotificationType!]\n) {\n  watchForNotifications(entity: $id, notifications: $notificationTypes) {\n    success\n    problems\n    watch {\n      enabledNotifications\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6d8ac8b235fbf1d9f4ea399f73e80926";

export default node;
