import React, { FC, Suspense, useMemo } from 'react';
import { useMatches } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItemType,
    DropdownItemsWithImageType,
    MagIcon,
    SignoutIcon,
    Tooltip,
    UserIcon2,
    UserInformation,
} from '@accesstel/pcm-ui';

import { Notification } from 'components/ThemedPage/notification/Notification';
import { ACTION_KEY_DEFAULT, useActionKey } from 'lib/hook';
import { logError } from 'lib/log';
import { RouteExtraData } from 'lib/route-helpers';
import { SuspenseObject } from 'lib/suspense';

import { SignOutReason, logout, useCurrentUser } from '../../lib/auth';
import { Paths } from '../../lib/routes';

interface PageHeaderProps {
    isSearchOpen: boolean;
    onOpenSearch: () => void;
}

export const PageHeader: FC<PageHeaderProps> = ({ isSearchOpen, onOpenSearch }) => {
    const currentUser = useCurrentUser();
    const actionKey = useActionKey();

    const dropdownItems: DropdownItemsWithImageType[] = [
        {
            to: Paths.Account,
            id: 'account-button',
            name: 'Personal Settings',
            icon: <UserIcon2 />,
        },
        {
            // FIXME: Get rid of the path requirement. This breaks the referrer path preventing the user from signing back in and going to the page they were on.
            to: '/',
            id: 'logout-button',
            name: 'Sign out',
            onClick: () => logout(SignOutReason.UserRequest),
            icon: <SignoutIcon />,
        },
    ];

    const matches = useMatches();

    const breadcrumbTitlesOrPromises = useMemo<BreadcrumbPart[]>(() => {
        const breadcrumbMatches = matches.slice(1);
        if (breadcrumbMatches.length > 0) {
            const lastMatch = breadcrumbMatches[breadcrumbMatches.length - 1];
            // remove last element if it is the same as the second last, but with the last one ending in /
            if (
                breadcrumbMatches.length > 1 &&
                lastMatch.pathname === breadcrumbMatches[breadcrumbMatches.length - 2].pathname + '/'
            ) {
                breadcrumbMatches.pop();
            }
        }

        // Dont show the root breadcrumb if we are on the root page
        if (breadcrumbMatches.length === 1 && breadcrumbMatches[0].pathname === '/') {
            return [];
        }

        const breadcrumbs: BreadcrumbPart[] = [];
        breadcrumbMatches.forEach(match => {
            const routeBreadcrumbData = match.handle as RouteExtraData<string> | undefined;

            if (!routeBreadcrumbData) {
                logError(`No breadcrumb data for ${match.pathname}`);
                breadcrumbs.push({
                    to: match.pathname,
                    title: match.pathname,
                });
                return;
            }

            // Handling customized prepend route items
            for (const prepend of routeBreadcrumbData.prependBreadcrumbItems ?? []) {
                const resolvedPath = typeof prepend.to === 'string' ? prepend.to : prepend.to(match.params);

                if (typeof prepend.title === 'string') {
                    breadcrumbs.push({
                        to: resolvedPath,
                        title: prepend.title,
                    });
                    continue;
                } else {
                    const titleOrPromise = prepend.title(match.params, match.data);

                    if (typeof titleOrPromise === 'string') {
                        breadcrumbs.push({
                            to: resolvedPath,
                            title: titleOrPromise,
                        });
                        continue;
                    } else {
                        breadcrumbs.push({
                            to: resolvedPath,
                            title: new SuspenseObject(titleOrPromise),
                        });
                        continue;
                    }
                }
            }

            // Handling original route item
            if (typeof routeBreadcrumbData.title === 'string') {
                breadcrumbs.push({
                    to: match.pathname,
                    title: routeBreadcrumbData.title,
                });
            } else {
                const titleOrPromise = routeBreadcrumbData.title(match.params, match.data);

                if (typeof titleOrPromise === 'string') {
                    breadcrumbs.push({
                        to: match.pathname,
                        title: titleOrPromise,
                    });
                } else {
                    breadcrumbs.push({
                        to: match.pathname,
                        title: new SuspenseObject(titleOrPromise),
                    });
                }
            }
        });
        return breadcrumbs;
    }, [matches]);

    return (
        <div className='flex flex-row justify-between mb-6'>
            <Suspense fallback={<div></div>}>
                <BreadcrumbArea breadcrumbTitlesOrPromises={breadcrumbTitlesOrPromises} />
            </Suspense>
            <div className='flex flex-row gap-6 items-center'>
                <Notification />
                <Tooltip
                    content={
                        <div className='flex flex-row gap-1 items-center justify-center'>
                            <span className='pr-1'>Global Search</span>
                            <kbd className='bg-grayRegular text-eggplantRegular px-1 py-0.5 rounded'>
                                {actionKey ?? ACTION_KEY_DEFAULT}
                            </kbd>
                            <span>+</span>
                            <kbd className='bg-grayRegular text-eggplantRegular px-1 py-0.5 rounded'>K</kbd>
                        </div>
                    }
                >
                    <div
                        className={`w-4 h-4 cursor-pointer ${
                            isSearchOpen ? 'text-coralRegular' : 'text-eggplantRegular'
                        } hover:text-coralRegular`}
                        onClick={e => {
                            e.stopPropagation();
                            onOpenSearch();
                        }}
                    >
                        <MagIcon />
                    </div>
                </Tooltip>
                <UserInformation username={currentUser?.name ?? 'User'} dropdownItems={dropdownItems} />
            </div>
        </div>
    );
};

interface BreadcrumbPart {
    to: string;
    title: string | SuspenseObject<string>;
}

interface BreadcrumbAreaProps {
    breadcrumbTitlesOrPromises: BreadcrumbPart[];
}

export const BreadcrumbArea: FC<BreadcrumbAreaProps> = ({ breadcrumbTitlesOrPromises }) => {
    const breadcrumbs: BreadcrumbItemType[] = breadcrumbTitlesOrPromises.map(breadcrumb => {
        if (typeof breadcrumb.title === 'string') {
            return {
                to: breadcrumb.to,
                name: breadcrumb.title,
            };
        } else {
            return {
                to: breadcrumb.to,
                name: breadcrumb.title.read(),
            };
        }
    });

    return <Breadcrumb data={breadcrumbs} />;
};
