import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { useDocumentTitle } from '../../../components';
import { NotificationSettingsContent } from './NotificationSettingsContent';
import { NotificationSettingsSkeleton } from './NotificationSettingsSkeleton';
import { NotificationSettingsContentQuery } from './__generated__/NotificationSettingsContentQuery.graphql';

export const NotificationSettings: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<NotificationSettingsContentQuery>;

    useDocumentTitle('Notification Settings');

    return (
        <>
            {queryRef && (
                <Suspense fallback={<NotificationSettingsSkeleton />}>
                    <NotificationSettingsContent queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
