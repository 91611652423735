import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import {
    CheckCircledOpenIcon,
    DoubleTickIcon,
    FilterContainer,
    NavigateTarget,
    SettingsIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import { graphql } from 'babel-plugin-relay/macro';
import { NotificationEntry } from 'components/NotificationEntry';
import { getFormattedDateFromDate } from 'lib/dateFormatter';
import { logError } from 'lib/log';
import { Paths } from 'lib/routes';
import { DateTime } from 'luxon';

import { NotificationPaneMarkAllAsReadMutation } from './__generated__/NotificationPaneMarkAllAsReadMutation.graphql';
import { NotificationPaneMarkAsReadMutation } from './__generated__/NotificationPaneMarkAsReadMutation.graphql';
import { NotificationPane_data$data, NotificationPane_data$key } from './__generated__/NotificationPane_data.graphql';

type Notification = NotificationPane_data$data['data'][number];

interface NotificationPaneProps {
    unreadCount: number;
    recount: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    notificationRef: NotificationPane_data$key;
}

export const NotificationPane: FC<NotificationPaneProps> = ({ setIsOpen, unreadCount, recount, notificationRef }) => {
    const navigate = useExtendedNavigate();
    const environment = useRelayEnvironment();

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const scrollPositionRef = useRef<number>(0);

    const { data, total: notificationCount } = useFragment(Fragment, notificationRef);
    const [markAllAsRead] = useMutation<NotificationPaneMarkAllAsReadMutation>(NotificationMarkAllAsReadMutation);
    const [markAsRead] = useMutation<NotificationPaneMarkAsReadMutation>(NotificationMarkAsReadMutation);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollPositionRef.current;
        }
    }, [data]);

    const saveScrollPosition = () => {
        if (scrollContainerRef.current) {
            scrollPositionRef.current = scrollContainerRef.current.scrollTop;
        }
    };

    const navigateToNotificationHistory = () => {
        setIsOpen(false);
        navigate(Paths.AccountNotificationHistory);
    };

    const navigateToNotificationSettings = () => {
        setIsOpen(false);
        navigate(Paths.AccountNotificationSettings);
    };

    const markAllAsReadHandler = () => {
        saveScrollPosition();
        markAllAsRead({
            variables: {},
            onCompleted: () => {
                fetchQuery(environment, ReloadQuery, {});
                recount();
            },
            onError: error => {
                logError('Failed to mark all notifications as read', error);
            },
        });
    };

    const markAsReadHandler = (id: string) => {
        saveScrollPosition();
        markAsRead({
            variables: { id },
            onCompleted: () => {
                fetchQuery(environment, ReloadQuery, {});
                recount();
            },
            onError: error => {
                logError(`Failed to mark notification [${id}] as read`, error);
            },
        });
    };

    const title = unreadCount && unreadCount > 0 ? `Notifications (${unreadCount} unread)` : 'Notifications';

    const notificationsByDate = groupNotificationsByDate([...data]);
    const notificationBlocks = Object.keys(notificationsByDate).map(date => {
        return (
            <div key={date} className='flex flex-col gap-2 pb-2'>
                <span className='text-xs text-eggplantLight'>{date}</span>
                {notificationsByDate[date].map(notification => {
                    return (
                        <NotificationEntry
                            key={notification.id}
                            notification={notification}
                            onClick={() => {
                                markAsReadHandler(notification.id);
                                if (notification.link) {
                                    // this is coming from the API so its always an external link
                                    window.open(notification.link, '_blank');
                                    return;
                                }

                                const internalLink = constructInternalLink(notification);
                                if (internalLink) {
                                    setIsOpen(false); // Close the notification pane before navigating away
                                    navigate(internalLink);
                                }
                            }}
                        />
                    );
                })}
            </div>
        );
    });

    const notificationBody =
        notificationCount === 0 ? (
            <div className='h-full w-full text-eggplantRegular flex flex-col gap-2 items-center justify-center'>
                <div className='w-6 h-6'>
                    <CheckCircledOpenIcon />
                </div>
                <span className='text-xs font-light'>Notification inbox is empty</span>
            </div>
        ) : (
            notificationBlocks
        );

    return (
        <FilterContainer
            title={title}
            onClose={() => setIsOpen(false)}
            // hideClearButton={notificationCount === 0}
            hideClearButton // FIXME: PCM-2697 Uncomment the above line when the "Notification History" page has been implemented
            customButton={<div className='text-coralRegular text-xs font-light'>View all</div>}
            onClearClick={navigateToNotificationHistory}
            hideConfirmButton={true}
            width='w-96'
            primaryContent={
                <div ref={scrollContainerRef} className='flex flex-col h-96 w-full gap-4 px-3 pb-2 overflow-y-scroll'>
                    {notificationBody}
                </div>
            }
            headerButtons={[
                {
                    icon: <DoubleTickIcon />,
                    onClick: markAllAsReadHandler,
                    tooltip: 'Mark all as read',
                },
                {
                    icon: <SettingsIcon />,
                    onClick: navigateToNotificationSettings,
                    tooltip: 'Notification settings',
                },
            ]}
        />
    );
};

const groupNotificationsByDate = (notifications: Notification[]): Record<string, Notification[]> => {
    // Today, Yesterday, and absolute date for the rest
    const groups: Record<string, Notification[]> = {};
    const today = DateTime.local().startOf('day');
    const yesterday = today.minus({ days: 1 });

    notifications.forEach(notification => {
        const date = DateTime.fromISO(notification.timestamp);
        let key;
        if (date > today) {
            key = 'Today';
        } else if (date > yesterday) {
            key = 'Yesterday';
        } else {
            key = getFormattedDateFromDate(date.toJSDate());
        }

        if (!groups[key]) {
            groups[key] = [];
        }

        groups[key].push(notification);
    });

    return groups;
};

const constructInternalLink = (notification: Notification): NavigateTarget | null => {
    if (notification.link) {
        // eslint-disable-next-line no-console
        console.warn('Notification has an external link, not creating internal link');
        return null;
    }

    if (!notification.context) {
        return null;
    }

    switch (notification.type) {
        case 'GeneratorRun':
            if (notification.context.__typename !== 'GeneratorRunReport') {
                return null;
            }
            return {
                pathname: Paths.TasksGeneratorsDetails,
                params: {
                    id: notification.context.id,
                },
            };

        case 'ScheduledBatteryTestComplete':
            if (notification.context.__typename !== 'BatteryTest') {
                return null;
            }
            return {
                pathname: Paths.ViewTaskDetails,
                params: {
                    id: notification.context.id,
                },
            };
        case 'BatteryDischarge': {
            if (notification.context.__typename !== 'DeviceBatteryTestResults') {
                return null;
            }
            const isExternal = notification.context.task === null;
            if (isExternal) {
                return {
                    pathname: Paths.ViewExternalTestResults,
                    params: {
                        id: notification.context.id,
                    },
                };
            } else {
                return {
                    pathname: Paths.ViewTaskDeviceResults,
                    params: {
                        taskId: notification.context.task!.id,
                        deviceId: notification.context.device.id,
                    },
                };
            }
        }
        case 'ProductUpdate':
        case '%future added value':
        default:
            return null;
    }
};

const Fragment = graphql`
    fragment NotificationPane_data on PaginatedNotifications {
        total
        data {
            id
            type
            unread
            timestamp
            message
            link
            context {
                __typename
                ... on GeneratorRunReport {
                    id
                }
                ... on BatteryTest {
                    id
                }
                ... on DeviceBatteryTestResults {
                    id
                    task {
                        id
                    }
                    device {
                        id
                    }
                }
            }
        }
    }
`;

const ReloadQuery = graphql`
    query NotificationPaneReloadQuery {
        notifications {
            ...NotificationPane_data
        }
    }
`;

const NotificationMarkAllAsReadMutation = graphql`
    mutation NotificationPaneMarkAllAsReadMutation {
        markAllNotificationsAsRead
    }
`;

const NotificationMarkAsReadMutation = graphql`
    mutation NotificationPaneMarkAsReadMutation($id: ID!) {
        markNotificationAsRead(id: $id) {
            id
            unread
        }
    }
`;
