import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Link, ListRow, ListView } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterValueMap } from 'filters/common';
import { DeviceAllFilterMap, StaticDeviceFilterDefinitions } from 'filters/device/settings';
import { encodeFilterParameters } from 'lib/table-filter';

import { DeviceExtraFilters, DeviceTableColumn, DeviceTableColumnId } from '../../../../../../filters/device';
import { SortDirection } from '../../../../../../layouts';
import { Paths } from '../../../../../../lib/routes';
import { createSortParams } from '../../../../../../lib/table-sort';
import { getSectionTimeDescription } from '../../../common';
import { TimeRange, TimeRangeSearchParameter } from '../../../settings';
import { DeviceRow } from '../components/DeviceRow';
import { IncidentsCountChart } from '../components/IncidentCountsChart';
import { ACSiteInternalReliability_site$key } from './__generated__/ACSiteInternalReliability_site.graphql';

export interface ACSiteInternalReliabilityProps {
    fragmentRef: ACSiteInternalReliability_site$key;
    timeRange: TimeRange;
}

export const ACSiteInternalReliability: FC<ACSiteInternalReliabilityProps> = ({ fragmentRef, timeRange }) => {
    const { leastReliableDevices, acPower, name: siteName } = useFragment(Fragment, fragmentRef);

    const devices = leastReliableDevices.data.filter(device => device.acPower.reliability.mtbf.current != null);

    return (
        <>
            <div className='col-span-12 font-bold text-xl mt-6 mb-2'>
                Internal reliability{' '}
                <span className='font-light text-xs'>{getSectionTimeDescription(timeRange, 'site')}</span>
            </div>
            <div className='col-span-12 lg:col-span-6 xl:col-span-4'>
                <ListView
                    title='Least reliable devices on site'
                    view='list'
                    listDisplay='grid'
                    contentClassName='grid-rows-5'
                >
                    {devices.map((device, index) => (
                        <ListRow
                            key={device.id}
                            content={
                                <DeviceRow
                                    name={device.name}
                                    mtbfInSeconds={device.acPower?.reliability?.mtbf?.current}
                                    mttrInSeconds={device.acPower?.reliability?.mttr?.current}
                                />
                            }
                            label={String(index + 1)}
                            link={{
                                pathname: ':deviceId',
                                params: {
                                    deviceId: device.id,
                                },
                                search: {
                                    [TimeRangeSearchParameter]: timeRange,
                                    ...encodeFilterParameters<DeviceTableColumnId, FilterValueMap<DeviceAllFilterMap>>(
                                        {
                                            [DeviceTableColumn.Site]: [
                                                {
                                                    type: 'result',
                                                    name: devices[0].site.name,
                                                },
                                            ],
                                        },
                                        StaticDeviceFilterDefinitions
                                    ),
                                },
                            }}
                        />
                    ))}
                    {devices.length === 0 && (
                        <div className='grid place-items-center' style={{ height: '56px' }}>
                            There are no reliability issues
                        </div>
                    )}
                </ListView>
                <Link
                    to={{
                        pathname: Paths.ReportACPowerDeviceList,
                        search: {
                            ...createSortParams(DeviceTableColumn.ACReliability, SortDirection.Ascending),
                            ...encodeFilterParameters<DeviceTableColumnId, FilterValueMap<DeviceAllFilterMap>>(
                                {
                                    [DeviceTableColumn.Site]: [
                                        {
                                            type: 'result',
                                            name: siteName,
                                        },
                                    ],
                                    [DeviceExtraFilters.ACReliabilityTimeRange]: timeRange,
                                },
                                StaticDeviceFilterDefinitions
                            ),
                        },
                    }}
                    className='text-coralRegular float-right mt-1'
                >
                    See more
                </Link>
            </div>
            <div className='col-span-12 lg:col-span-6 xl:col-span-8 h-40 lg:h-auto'>
                <div className='flex flex-col h-full gap-2'>
                    <div className='font-bold'>Incident counts</div>
                    <IncidentsCountChart fragmentRef={acPower} />
                </div>
            </div>
        </>
    );
};

const Fragment = graphql`
    fragment ACSiteInternalReliability_site on Site {
        name
        leastReliableDevices: devices(
            pageSize: 5
            orderBy: { field: ACReliability, dir: Asc }
            acReliabilityTimeRange: $timeRange
        ) {
            data {
                id
                name
                site {
                    name
                }
                acPower {
                    reliability(timeRange: $timeRange, unit: Second) {
                        mttr {
                            current
                        }
                        mtbf {
                            current
                        }
                    }
                }
            }
        }
        acPower {
            ...IncidentCountsChart_acPower
        }
    }
`;
