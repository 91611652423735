import React, { FC, useMemo } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { Outlet, generatePath, useMatch, useParams } from 'react-router-dom';

import { Menu, MenuItem, PageHeading, SiteViewNavigation, useExtendedNavigate } from '@accesstel/pcm-ui';
import { DeviceCategory, SiteViewDeviceGroup } from '@accesstel/pcm-ui/dist/navigation/SiteViewNavigation/types';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { AlertBanner } from 'components/AlertBanner';
import { useUserPermissions } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { MenuItemGroup } from 'lib/menu';
import { Paths } from 'lib/routes';
import { formatSiteName } from 'lib/textFormatters';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { NotificationWatchControl } from '../../../components/NotificationWatchControl';
import {
    DeviceCategory as DeviceCategoryModel,
    SiteViewLayoutContentQuery,
    SiteViewLayoutContentQuery$data,
} from './__generated__/SiteViewLayoutContentQuery.graphql';
import { getNavigationOptions } from './lib';

type SiteDevice = NonNullable<SiteViewLayoutContentQuery$data['site']>['devices']['data'][number];

export interface SiteViewLayoutContentProps {
    queryRef: PreloadedQuery<SiteViewLayoutContentQuery>;
}

export const SiteViewLayoutContent: FC<SiteViewLayoutContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(SiteViewQuery, queryRef);
    const { deviceId, siteId } = useParams() as { deviceId: string; siteId: string };
    const match = useMatch(Paths.SiteViewViewSiteDevicePage);
    const onOverviewPageMatch = !!useMatch(Paths.SiteViewViewSiteOverview);
    const navigate = useExtendedNavigate();
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();

    const page = match?.params.page ?? 'overview';

    const site = data.site;

    useDocumentTitle(`${site?.name ?? 'Site'}`);

    const devices = useMemo(() => {
        if (!site) {
            return [];
        }

        return createNavigationDevices(site.devices.data, page);
    }, [site, page]);

    if (!site) {
        return <ErrorNotFound />;
    }

    let siteLocation: string;
    if (site.address.address) {
        siteLocation = formatSiteName(site.address.address, site.address.state);
    } else {
        siteLocation = site.address.state;
    }

    const viewEditSiteMenuItems: MenuItem[] = [
        {
            name: 'Add a new device',
            onClick: () => navigate({ pathname: Paths.AddDevice, search: { site: siteId } }),
            disabled: !hasAssetsWrite,
            group: MenuItemGroup.Assets,
        },
        {
            name: hasAssetsWrite ? 'Edit site' : 'View site',
            onClick: () => {
                navigate({ pathname: Paths.EditSite, params: { id: siteId } });
            },
            disabled: !hasAssetsRead,
            group: MenuItemGroup.Assets,
        },
    ];

    return (
        <div className='space-y-6 mb-32'>
            <div className='flex flex-row justify-between'>
                <PageHeading value={formatSiteName(site.name, site.address.state)} />
                <div className='flex flex-row gap-2'>
                    <NotificationWatchControl entityId={siteId} entityType='site' />
                    <Menu
                        id='site-view-menu'
                        groups={[{ key: MenuItemGroup.Assets, title: MenuItemGroup.Assets }]}
                        menuItems={viewEditSiteMenuItems}
                    />
                </div>
            </div>
            <div>
                <div className='font-light text-sm'>
                    <span className='text-coralRegular'>Site Location: </span>
                    <span>{siteLocation}</span>
                </div>
            </div>
            <div className='w-full border-t border-eggplantExtraLight' />
            {devices.length > 0 && (
                <>
                    <SiteViewNavigation
                        site={{
                            to: generatePath(Paths.SiteViewViewSiteOverview, { siteId }),
                            title: 'Site',
                            site: {
                                id: siteId,
                                name: site.name,
                                state: site.address.state,
                            },
                        }}
                        devices={devices}
                        items={getNavigationOptions(deviceId ?? site.devices.data[0].id, onOverviewPageMatch)}
                    />
                    <Outlet context={devices} />
                </>
            )}
            {devices.length === 0 && (
                <AlertBanner
                    title='No Devices Found'
                    message='There are no devices associated with this site.'
                    action={
                        hasAssetsWrite
                            ? {
                                  label: 'Add a new device',
                                  onClick: () => navigate({ pathname: Paths.AddDevice, search: { site: siteId } }),
                              }
                            : undefined
                    }
                />
            )}
        </div>
    );
};

function createNavigationDevices(devices: readonly SiteDevice[], page: string): SiteViewDeviceGroup[] {
    const visited = new Set<string>();
    const deviceGroups: SiteViewDeviceGroup[] = [];

    for (const device of devices) {
        if (!device.dualPlaneCompanion?.device) {
            // Single plane
            deviceGroups.push({
                device: {
                    id: device.id,
                    name: device.name,
                    category: convertDeviceCategoryModel(device.type.category),
                },
                to: generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: device.id, page }),
            });
        } else {
            // Dual plane
            if (visited.has(device.id)) {
                continue;
            }

            const companion = device.dualPlaneCompanion.device;
            visited.add(companion.id);

            deviceGroups.push({
                device: {
                    id: device.id,
                    name: device.name,
                    category: convertDeviceCategoryModel(device.type.category),
                },
                to: generatePath(Paths.SiteViewRelativeDevicePage, { deviceId: device.id, page }),
                companion: {
                    id: companion.id,
                    name: companion.name,
                },
            });
        }
    }

    return deviceGroups;
}

export const SiteViewQuery = graphql`
    query SiteViewLayoutContentQuery($id: ID!) {
        site(id: $id) {
            name
            address {
                address
                state
            }
            devices(filters: { category: [PowerController, Generator, Gateway] }) {
                data {
                    id
                    name
                    type {
                        category
                    }
                    dualPlaneCompanion {
                        device {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export function loadSiteViewPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        SiteViewQuery,
        {
            id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}

function convertDeviceCategoryModel(category: DeviceCategoryModel): DeviceCategory {
    switch (category) {
        case 'PowerController':
        case 'Generator':
        case 'Gateway':
            return category;

        default:
            throw new Error(`Unknown device category: ${category}`);
    }
}
