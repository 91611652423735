/**
 * @generated SignedSource<<2222e7087f397c7bfa0fe50dd63d70cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTestMarkerSource = "Analytics" | "Collected" | "%future added value";
export type BatteryTestMarkerType = "Custom" | "DischargeStart" | "DischargeStop" | "ReserveTimeReached" | "ThresholdReached" | "%future added value";
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type MetricChartModalContentQuery$variables = {
  begin?: string | null;
  end?: string | null;
  interval?: string | null;
  testIds: ReadonlyArray<string>;
  unitTemperature?: UnitTemperature | null;
};
export type MetricChartModalContentQuery$data = {
  readonly batteryTestResults: {
    readonly data: ReadonlyArray<{
      readonly aggregatedCurrent: ReadonlyArray<{
        readonly average: number | null;
        readonly timestamp: string;
      }> | null;
      readonly aggregatedPower: ReadonlyArray<{
        readonly average: number | null;
        readonly timestamp: string;
      }> | null;
      readonly aggregatedTemperature: ReadonlyArray<{
        readonly average: number | null;
        readonly timestamp: string;
      }> | null;
      readonly aggregatedVoltage: ReadonlyArray<{
        readonly average: number | null;
        readonly timestamp: string;
      }> | null;
      readonly cause: BatteryTestReason;
      readonly commencedTime: string | null;
      readonly completedTime: string | null;
      readonly coupDeFouet: {
        readonly dip: number;
        readonly float: number;
        readonly plateauOffset: string;
        readonly plateauVoltage: number;
        readonly troughOffset: string;
        readonly troughVoltage: number;
      } | null;
      readonly device: {
        readonly battery: {
          readonly allowedVoltage: {
            readonly maximum: number | null;
            readonly minimum: number | null;
          };
          readonly quickTestCheckPercent: number | null;
          readonly quickTestFailThreshold: number | null;
          readonly reserveTime: number | null;
        };
      };
      readonly id: string;
      readonly markers: ReadonlyArray<{
        readonly description: string;
        readonly offset: string;
        readonly source: BatteryTestMarkerSource;
        readonly timestamp: string;
        readonly type: BatteryTestMarkerType;
      }>;
      readonly name: string | null;
      readonly task: {
        readonly settings: {
          readonly reserveTime?: number;
          readonly threshold?: number | null;
        };
      } | null;
    }>;
  };
};
export type MetricChartModalContentQuery = {
  response: MetricChartModalContentQuery$data;
  variables: MetricChartModalContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "testIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unitTemperature"
},
v5 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "testIds"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cause",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v11 = {
  "kind": "Variable",
  "name": "begin",
  "variableName": "begin"
},
v12 = {
  "kind": "Variable",
  "name": "end",
  "variableName": "end"
},
v13 = {
  "kind": "Variable",
  "name": "interval",
  "variableName": "interval"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timestamp",
  "storageKey": null
},
v15 = [
  (v14/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": [
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    {
      "kind": "Variable",
      "name": "unit",
      "variableName": "unitTemperature"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedTemperature",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v18 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedVoltage",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedCurrent",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": (v17/*: any*/),
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedPower",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryCoupDeFouet",
  "kind": "LinkedField",
  "name": "coupDeFouet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "float",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "troughVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "troughOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plateauVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plateauOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dip",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryTestResultMarker",
  "kind": "LinkedField",
  "name": "markers",
  "plural": true,
  "selections": [
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "source",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "threshold",
      "storageKey": null
    }
  ],
  "type": "BatteryTestTypeQuick",
  "abstractKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceBattery",
  "kind": "LinkedField",
  "name": "battery",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickTestFailThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickTestCheckPercent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Minute"
        }
      ],
      "kind": "ScalarField",
      "name": "reserveTime",
      "storageKey": "reserveTime(unit:\"Minute\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VoltageRange",
      "kind": "LinkedField",
      "name": "allowedVoltage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minimum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximum",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MetricChartModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BatteryTest",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v24/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "MetricChartModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BatteryTest",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v24/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3a541df46629e73dd366275eb5007ca",
    "id": null,
    "metadata": {},
    "name": "MetricChartModalContentQuery",
    "operationKind": "query",
    "text": "query MetricChartModalContentQuery(\n  $testIds: [String!]!\n  $begin: Duration\n  $end: Duration\n  $interval: Duration\n  $unitTemperature: UnitTemperature\n) {\n  batteryTestResults(ids: $testIds) {\n    data {\n      id\n      name\n      cause\n      commencedTime\n      completedTime\n      aggregatedTemperature(begin: $begin, end: $end, interval: $interval, unit: $unitTemperature) {\n        timestamp\n        average\n      }\n      aggregatedVoltage(begin: $begin, end: $end, interval: $interval) {\n        timestamp\n        average\n      }\n      aggregatedCurrent(begin: $begin, end: $end, interval: $interval) {\n        timestamp\n        average\n      }\n      aggregatedPower(begin: $begin, end: $end, interval: $interval) {\n        timestamp\n        average\n      }\n      coupDeFouet {\n        float\n        troughVoltage\n        troughOffset\n        plateauVoltage\n        plateauOffset\n        dip\n      }\n      markers {\n        timestamp\n        offset\n        type\n        source\n        description\n      }\n      task {\n        settings {\n          __typename\n          ... on BatteryTestTypeQuick {\n            reserveTime\n            threshold\n          }\n        }\n        id\n      }\n      device {\n        battery {\n          quickTestFailThreshold\n          quickTestCheckPercent\n          reserveTime(unit: Minute)\n          allowedVoltage {\n            minimum\n            maximum\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e08e950e4fdad76ab749d1ccc36c13a";

export default node;
