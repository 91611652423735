/**
 * @generated SignedSource<<cce9c2be3e4a269d03b4a03580c30d57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnitApparentPower = "KiloVoltAmp" | "VoltAmp" | "%future added value";
export type UnitCharge = "AmpHour" | "MilliAmpHour" | "%future added value";
export type UnitCurrent = "Amp" | "Milliamp" | "%future added value";
export type UnitEnergy = "KiloWattHour" | "WattHour" | "%future added value";
export type UnitFlowRate = "LitrePerHour" | "USGallonPerHour" | "%future added value";
export type UnitPower = "KiloWatt" | "Watt" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitReactivePower = "KiloVoltAmpReactive" | "VoltAmpReactive" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitVoltage = "Millivolt" | "Volt" | "%future added value";
export type UnitVolume = "Litre" | "USGallon" | "%future added value";
export type UpdateUserPreferenceErrorType = "InvalidColumnId" | "InvalidNotificationsEmailDelay" | "InvalidTableId" | "InvalidTimezone" | "%future added value";
export type UserUnitPreferencesIn = {
  apparentPower?: UnitApparentPower | null;
  charge?: UnitCharge | null;
  current?: UnitCurrent | null;
  energy?: UnitEnergy | null;
  power?: UnitPower | null;
  pressure?: UnitPressure | null;
  reactivePower?: UnitReactivePower | null;
  temperature?: UnitTemperature | null;
  voltage?: UnitVoltage | null;
  volume?: UnitVolume | null;
  volumetricFlowRate?: UnitFlowRate | null;
};
export type UnitSettingsUpdateMutation$variables = {
  input: UserUnitPreferencesIn;
};
export type UnitSettingsUpdateMutation$data = {
  readonly updateCurrentUserPreferences: {
    readonly problems: ReadonlyArray<UpdateUserPreferenceErrorType> | null;
    readonly success: boolean;
  };
};
export type UnitSettingsUpdateMutation = {
  response: UnitSettingsUpdateMutation$data;
  variables: UnitSettingsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "units",
            "variableName": "input"
          }
        ],
        "kind": "ObjectValue",
        "name": "preferences"
      }
    ],
    "concreteType": "UpdateUserPreferenceResult",
    "kind": "LinkedField",
    "name": "updateCurrentUserPreferences",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problems",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitSettingsUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitSettingsUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9b7cd99c451307a62e3645374d5aeeb0",
    "id": null,
    "metadata": {},
    "name": "UnitSettingsUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UnitSettingsUpdateMutation(\n  $input: UserUnitPreferencesIn!\n) {\n  updateCurrentUserPreferences(preferences: {units: $input}) {\n    success\n    problems\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed800ae4e8ca5e00d08504f5980c9a12";

export default node;
