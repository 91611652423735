import React, { FC, Suspense } from 'react';
import { PreloadedQuery } from 'react-relay';
import { useLoaderData } from 'react-router-dom';

import { useDocumentTitle } from '../../../components';
import { PersonalSettingsContent } from './PersonalSettingsContent';
import { PersonalSettingsSkeleton } from './PersonalSettingsSkeleton';
import { PersonalSettingsContentQuery } from './__generated__/PersonalSettingsContentQuery.graphql';

export const PersonalSettings: FC = () => {
    const queryRef = useLoaderData() as PreloadedQuery<PersonalSettingsContentQuery>;

    useDocumentTitle('Personal Settings');

    return (
        <>
            {queryRef && (
                <Suspense fallback={<PersonalSettingsSkeleton />}>
                    <PersonalSettingsContent queryRef={queryRef} />
                </Suspense>
            )}
        </>
    );
};
