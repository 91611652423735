/**
 * @generated SignedSource<<883c67d1526254c7f003e873b1ef752f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentChartBlocQuery$variables = {
  begin: string;
  bloc: number;
  end: string;
  id: string;
  interval: string;
  string: number;
};
export type CurrentChartBlocQuery$data = {
  readonly device: {
    readonly battery: {
      readonly bloc: {
        readonly data: {
          readonly current: {
            readonly " $fragmentSpreads": FragmentRefs<"ReportLineChart_data">;
          } | null;
        };
      } | null;
    };
  } | null;
};
export type CurrentChartBlocQuery = {
  response: CurrentChartBlocQuery$data;
  variables: CurrentChartBlocQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bloc"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "string"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "bloc",
    "variableName": "bloc"
  },
  {
    "kind": "Variable",
    "name": "string",
    "variableName": "string"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "begin",
    "variableName": "begin"
  },
  {
    "kind": "Variable",
    "name": "end",
    "variableName": "end"
  },
  {
    "kind": "Variable",
    "name": "interval",
    "variableName": "interval"
  },
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Amp"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentChartBlocQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "DeviceBatteryBloc",
                "kind": "LinkedField",
                "name": "bloc",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "BlocMetrics",
                    "kind": "LinkedField",
                    "name": "metrics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v8/*: any*/),
                        "concreteType": "TimeSeriesMetrics",
                        "kind": "LinkedField",
                        "name": "current",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportLineChart_data"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CurrentChartBlocQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBattery",
            "kind": "LinkedField",
            "name": "battery",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "DeviceBatteryBloc",
                "kind": "LinkedField",
                "name": "bloc",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "BlocMetrics",
                    "kind": "LinkedField",
                    "name": "metrics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v8/*: any*/),
                        "concreteType": "TimeSeriesMetrics",
                        "kind": "LinkedField",
                        "name": "current",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimestampMetric",
                            "kind": "LinkedField",
                            "name": "values",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timestamp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5d62991e60cfc2358e9d24a7fbbed91",
    "id": null,
    "metadata": {},
    "name": "CurrentChartBlocQuery",
    "operationKind": "query",
    "text": "query CurrentChartBlocQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n  $interval: Duration!\n  $string: Int!\n  $bloc: Int!\n) {\n  device(id: $id) {\n    battery {\n      bloc(string: $string, bloc: $bloc) {\n        data: metrics {\n          current(unit: Amp, begin: $begin, end: $end, interval: $interval) {\n            ...ReportLineChart_data\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ReportLineChart_data on TimeSeriesMetrics {\n  values {\n    timestamp\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "59b209d2082d12f39ae49b2d733bfc00";

export default node;
