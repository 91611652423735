import { ReactNode } from 'react';
import React from 'react';

import { BatteryChargingIcon, DeviceIcon, MessageSquareIcon, SiteIcon, Tooltip } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import { createColumnHelper } from '@tanstack/react-table';
import { NotificationWatchlistColumn } from 'filters/notification-watchlist';
import { ColumnWithId } from 'layouts';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { formatSiteName } from 'lib/textFormatters';

import { NotificationType, WatchlistTableRequestQuery$data } from './__generated__/WatchlistTableRequestQuery.graphql';

export type NotificationWatchlist =
    WatchlistTableRequestQuery$data['currentUser']['notificationWatches']['data'][number];

const columnHelper = createColumnHelper<NotificationWatchlist>();
export const EntityColumn = columnHelper.accessor('entity', {
    id: NotificationWatchlistColumn.Entity,
    header: 'Entity',
    exportHeader: 'Entity',
    cell: ({ cell }) => {
        const value = cell.getValue();
        let icon;
        let title;
        if (value.__typename === 'Device') {
            title = value.name;
            icon = <DeviceIcon />;
        } else if (value.__typename === 'Site') {
            title = formatSiteName(value.name, value.address.state);
            icon = <SiteIcon />;
        } else if (value.__typename === 'BatteryTest') {
            title = value.entityName;
            icon = <BatteryChargingIcon />;
        }

        return (
            <div className='flex items-center space-x-2'>
                <div className='w-4 h-4'>{icon}</div>
                <span>{title}</span>
            </div>
        );
    },
    exportValue: ({ value }) => {
        if (value.__typename === 'Device') {
            return value.name;
        } else if (value.__typename === 'Site') {
            return formatSiteName(value.name, value.address.state);
        } else if (value.__typename === 'BatteryTest') {
            return value.entityName;
        }
        return '';
    },
    meta: {
        filterable: false,
        sortable: true,
    },
});

export const UpdatedTimeColumn = columnHelper.accessor('updatedAt', {
    id: NotificationWatchlistColumn.UpdatedTime,
    header: 'Updated',
    exportHeader: 'Updated',
    cell: ({ getValue }) => {
        const value = getValue();
        if (!value) {
            captureMessage('Found a watchlist with no updated time');
            return null;
        }
        return getDateTimeFormat(value);
    },
    exportValue: ({ value }) => value,
    meta: {
        filterable: false,
        sortable: true,
    },
});

export const EnabledNotificationsColumn = columnHelper.accessor('enabledNotifications', {
    id: NotificationWatchlistColumn.EnabledNotifications,
    header: 'Notifications',
    exportHeader: 'Notifications',
    cell: ({ cell }) => displayEnabledNotifications([...cell.getValue()]),
    exportValue: ({ value }) => value.toSorted().map(formatNotificationType).join(', '),
    meta: {
        filterable: false,
        sortable: false,
    },
});

export const NotificationWatchlistBaseTableColumns = [
    EntityColumn,
    UpdatedTimeColumn,
    EnabledNotificationsColumn,
] as ColumnWithId<NotificationWatchlistColumn, NotificationWatchlist>[];

function displayEnabledNotifications(notifications: NotificationType[]): ReactNode {
    if (notifications.length === 0) {
        captureMessage('Found a watchlist with no enabled notifications');
        return <span className='text-coralRegular italic'>None</span>;
    }

    const formattedNotificationTypes = notifications.map(formatNotificationType);

    if (formattedNotificationTypes.length <= 2) {
        return formattedNotificationTypes.join(' and ');
    }

    const content =
        formattedNotificationTypes.slice(0, -1).join(', ') + ', and ' + formattedNotificationTypes.slice(-1);
    return (
        <div className='flex gap-2 items-center'>
            <span>{formattedNotificationTypes.length} types</span>
            <Tooltip content={content}>
                <div className='w-4 h-4'>
                    <MessageSquareIcon />
                </div>
            </Tooltip>
        </div>
    );
}

function formatNotificationType(notificationType: NotificationType): string {
    switch (notificationType) {
        case 'BatteryDischarge':
            return 'Battery Discharge';
        case 'GeneratorRun':
            return 'Generator Run';
        case 'ScheduledBatteryTestComplete':
            return 'Scheduled Battery Test Complete';
        case 'ProductUpdate':
            return 'Product Update';
        default:
            captureMessage(`Unhandled notification type: ${notificationType}`, scope => {
                scope.setTag('Function', 'formatNotificationType');
                scope.setExtra('Notification Type', notificationType);
                return scope;
            });
            return 'Unknown';
    }
}
