/**
 * @generated SignedSource<<53275d6ada7e4629058f7d4d03f143b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationUnreadTotalQuery$variables = Record<PropertyKey, never>;
export type NotificationUnreadTotalQuery$data = {
  readonly notifications: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationPane_data">;
  };
  readonly unreadNotifications: {
    readonly total: number;
  };
};
export type NotificationUnreadTotalQuery = {
  response: NotificationUnreadTotalQuery$data;
  variables: NotificationUnreadTotalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v1 = {
  "alias": "unreadNotifications",
  "args": [
    {
      "kind": "Literal",
      "name": "filters",
      "value": {
        "read": {
          "value": false
        }
      }
    }
  ],
  "concreteType": "PaginatedNotifications",
  "kind": "LinkedField",
  "name": "notifications",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": "notifications(filters:{\"read\":{\"value\":false}})"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationUnreadTotalQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedNotifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationPane_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationUnreadTotalQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "PaginatedNotifications",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Notification",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unread",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "context",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "GeneratorRunReport",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "BatteryTest",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BatteryTest",
                        "kind": "LinkedField",
                        "name": "task",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "DeviceBatteryTestResults",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "BatteryType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "Device",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "Site",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "455061648c91aa63bead1ee376f4c674",
    "id": null,
    "metadata": {},
    "name": "NotificationUnreadTotalQuery",
    "operationKind": "query",
    "text": "query NotificationUnreadTotalQuery {\n  unreadNotifications: notifications(filters: {read: {value: false}}) {\n    total\n  }\n  notifications {\n    ...NotificationPane_data\n  }\n}\n\nfragment NotificationPane_data on PaginatedNotifications {\n  total\n  data {\n    id\n    type\n    unread\n    timestamp\n    message\n    link\n    context {\n      __typename\n      ... on GeneratorRunReport {\n        id\n      }\n      ... on BatteryTest {\n        id\n      }\n      ... on DeviceBatteryTestResults {\n        id\n        task {\n          id\n        }\n        device {\n          id\n        }\n      }\n      ... on BatteryType {\n        id\n      }\n      ... on Device {\n        id\n      }\n      ... on Site {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bafbb5f79f334aec07ddd60b235f47e";

export default node;
